<template>
  <CollapsibleSection :barText="'Property Information'"
                      :toggleHeaderColor=false
                      :bottomBorder=false
                      class='sl-mb-20px'>
    <table class='sl-simple-table-1 espm-table-1'>
      <tbody>
        <tr>
          <td>Primary Function</td>
          <td>{{ property?.primary_function }}</td>
          <td>Last Updated</td>
          <td>{{ property?.last_updated_date }}</td>
        </tr>
        <tr>
          <td>Energy Star Property ID</td>
          <td>{{ property?.espm_api_property_id }}</td>
          <td>First Bill Date</td>
          <td>{{ property?.first_bill_date }}</td>
        </tr>
        <tr>
          <td>Gross Floor Area</td>
          <td>{{ property?.gross_floor_area }}</td>
          <td>Construction Status</td>
          <td>{{ property?.construction_status }}</td>
        </tr>
        <tr>
          <td>Year Built</td>
          <td>{{ property?.year_built }}</td>
          <td>Federal Property</td>
          <td>{{ property?.federal_property }}</td>
        </tr>
        <tr>
          <td>Occupancy Percentage</td>
          <td>{{ property?.occupancy_percentage }}</td>
          <td>Number of Buildings</td>
          <td>{{ property?.number_of_buildings }}</td>
        </tr>
        <tr>
          <td>Number of Meters</td>
          <td>{{ property?.number_of_meters }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Notes</td>
          <td>{{ property?.notes }}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <br>
  </CollapsibleSection>
</template>
<script>
import CollapsibleSection from '../../../components/CollapsibleSection.vue';

export default {
  components: {
    CollapsibleSection,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
};
</script>
