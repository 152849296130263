<template>
  <div class="sl-progress sl-mt-30px sl-mb-40px">
    <strong class="sl-progress-big-header">{{ project_number_string }}</strong>
    <div class="sl-ml-5px">{{ progress_header }}</div>
  </div>

  <div class="sl-progress sl-mt-40px">
    <strong class="sl-mt-20px sl-progress-big-header">Check your progress as you work.</strong>
  </div>

  <div class='sl-progress-checks'>
    <div class='sl-progress-outer-check'>
      <div class='sl-progress-checkbox'>
        <label for='progress_1'>Report Started
          <input type="checkbox" :name="'progress_1'" :id="'progress_1'" :checked="report_started" disabled="disabled">
          <span class="sl-progress-checkmark"></span>
        </label>
      </div>
      <div class='sl-progress-checkbox'>Percentage of Completed Sections
        <h1 class='sl-progress-percentage'>{{ percentage_completed }}</h1>
      </div>
      <template v-if="project_type === 'cpm'">
        <div class='sl-progress-checkbox'>
          <label for='progress_2'>Photolog Created
            <input type="checkbox" :name="'progress_2'" :id="'progress_2'" :checked="photolog_created" disabled="disabled">
            <span class="sl-progress-checkmark"></span>
          </label>
        </div>
      </template>
      <div class='sl-progress-checkbox'>
        <label for='progress_3'>Report Created
          <input type="checkbox" :name="'progress_3'" :id="'progress_3'" :checked="report_created" disabled="disabled">
          <span class="sl-progress-checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      project_id: null,
      project_type: null,
      project_scope_id: null,
      project_number_string: null,
      progress_header: null,
      report_started: null,
      percentage_completed: null,
      photolog_created: null,
      report_created: null,
      report_submitted: null,
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const project_id = urlParams.get('id');
    const project_type = urlParams.get('project_type');
    const project_scope_id = urlParams.get('project_scope_id');

    await axios.get(`/${project_type}_data_items/return_progress_data`, {
      params: {
        project_id,
        project_type,
        project_scope_id,
      },
    })
      .then((response) => {
        this.project_id = project_id;
        this.project_type = project_type;
        this.project_scope_id = project_scope_id;
        this.project_number_string = response.data.project_number_string;
        this.progress_header = response.data.progress_header;
        this.report_started = response.data.report_started;
        this.percentage_completed = response.data.percentage_completed;
        this.photolog_created = response.data.photolog_created;
        this.report_created = response.data.report_created;
        this.report_submitted = response.data.report_submitted;
      }).catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>

</style>
