<template>
  <td :style="{ width: data?.width, cursor: data?.cursor }">
    <div class='sl-tooltip'>
      <!-- Dropdown to filter measures -->
      <select v-if='data?.filter' class='header-select' @change='filter($event)'>
        <option selected value=''>{{ data?.header }}</option>
        <option v-for="option in data?.options" :value=option>{{ option }}</option>
      </select>
      <!-- Header text -->
      <span v-else v-html='data?.header'></span>
      <!-- Tooltip text -->
      <span v-html='data?.tooltip_text' class='sl-tooltiptext sl-ewem-tooltiptext'></span>
    </div>
  </td>
</template>
<script>
export default {
  props: {
    data: Object,
  },

  methods: {
    filter(event) {
      this.$emit('filter', this.data.header, event.target.value);
    },
  },
};
</script>
<style scoped>
  .sl-ewem-tooltiptext {
    font-size: 12px !important;
    color: var(--dark-charcoal) !important;
    font-weight: normal !important;
    top: 115%;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    white-space: nowrap;
    margin-left: v-bind('data?.tooltip_margin_left');
    text-align: left !important;
  }

  .sl-ewem-tooltiptext::before {
    left: v-bind('data?.tooltip_arrow_left');
  }

  .sl-ewem-tooltiptext::after {
    left: v-bind('data?.tooltip_arrow_left');
  }

  .header-select {
    border: 0;
    color: #333E47;
    font-family: Roboto;
    width: 150px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
  }

  .header-select:focus {
    outline: none;
  }
</style>
