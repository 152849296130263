<template>
  <SpinningModal v-if="showLoadingModal"/>
  <EwemImportModal v-if="showImportModal"
                    :projectId="projectId"
                    :projectScopeId="projectScopeId"
                    @close-modal="showImportModal=false;"
                    @import-complete="uploadComplete()"/>
  <div class='sl-mt-10px sl-mb-40px sl-flex-align-items-center'>
    <h1 class='nd_photos_h1_style'>
      <span v-if='projectTrackingView'>Project Tracking</span>
      <span v-else>Energy and Water Efficiency Measures</span>
    </h1>
  </div>
  
  <div class='sl-mt-50px sl-mb-10px sl-flex-align-items-center'>
      <span @click='showImportModal=true;' class='sl-cursor-pointer figma-icon-link sl-bold-text sl-ml-5px'
            style='font-size: 26px;' title='Import'>+</span>
      <button @click='createEwem()' class='sl-simple-outline-btn sl-mlr-10px'>Add Rows</button>
      <input type='number' min='1' :max='maxRowsAllowed' v-model='rowsToAdd'
             class='sl-border-1px-medium-charcoal sl-w-60px sl-pl-5px sl-ptb-5px'
             id='row_number'/>
    <span v-if='alertMessage' class='sl-ml-10px sl-bold-text sl-font-size-14px' style='color: #F04C25'>{{ alertMessage }}</span>
  </div>
  <form @input='tableChanged' class="sl-flex-justify-content-center" :key="componentKey">
    <!-- Description Column (Fixed) -->
    <table class="sl-esg-decarbonization-table" id="sl-ewem-table">
      <thead>
        <tr class='ewem-top-header'>
          <td class="sl-w-45px"></td>
          <td class="sl-w-30px sl-border-l-1px-transparent"></td>
          <td class="sl-w-30px sl-border-l-1px-transparent"></td>
          <td class="sl-w-355px sl-border-r-1px-medium-charcoal"></td>
        </tr>
        <tr class='ewem-bottom-header'>
          <td class="sl-w-45px"></td>
          <td class="sl-w-30px sl-border-l-1px-transparent"></td>
          <td class="sl-w-30px sl-border-l-1px-transparent"></td>
          <EwemHeaderCell :data='headers.description' class='sl-border-r-1px-medium-charcoal'/>
        </tr>
      </thead>
      <div v-if="!filteredResults" class='empty-row sl-border-b-1px sl-border-r-1px-medium-charcoal'>There are no measures for the selected filters.</div>
      <div v-else-if="!ewems.length" class='empty-row sl-border-b-1px sl-border-r-1px-medium-charcoal'>There are no measures to display.</div>
      <tbody id='pl_table_body'>
        <tr v-for="ewem in ewems" 
            :id="ewem.id"
            class="sl-table__row sl-table__row--esg"
            :class="{ 'sl-border-b-1px': isLastRow(ewem) }">
          <td v-if='ewem.show' class="sl-w-45px">
            <span @click='toggleExpand(ewem)'
                  class="sl-cursor-pointer sl-pr-10px sl-bold-text" style="color: #005199">
              <span v-if="ewem.expand" style="font-size: 30px">-</span>
              <span v-else class="sl-font-size-20px">+</span>
            </span>
            <span class="sl-bold-text" style="margin-top: 2px;">{{ `${ewem.index_number}.` }}</span>
          </td>
          <td v-if='ewem.show' class='sl-w-30px sl-cursor-pointer sortable-handle'>
            <handle class='sl-mb-5px'/>
          </td>
          <td v-if='ewem.show'>
            <div class="sl-w-30px sl-flex-justify-align-center">
              <deleteIcon @click="deleteEwem(ewem)" transform='scale(0.7)' class="figma-icon-delete links-center"/>
            </div>
          </td>
          <td v-if='ewem.show' class="sl-w-355px sl-border-r-1px-medium-charcoal">
            <textarea v-model="ewem.description" column="description"
                      class="sl-w-355px sl-overflow-y-scroll sl-pl-10px"
                      :class="{ expandedTextCell: ewem.expand}">
            </textarea>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Main Table Body -->
    <div class="sl-esg-overflow-container">
      <table class="sl-esg-decarbonization-table" id="sl-ewem-table">
        <thead>
          <tr class='ewem-top-header'>
            <td class="sl-w-100px"><!-- Total Installed Cost --></td>
            <td class="sl-w-100px sl-border-l-1px-transparent"><!-- Base Installed Cost --></td>
            <td class="sl-w-175px sl-border-l-1px-transparent"><!-- Cost Certainty --></td>
            <td class="sl-w-100px sl-border-l-1px-transparent"><!-- Existing RUL --></td>
            <td class="sl-w-100px"><!-- Electricity --></td>
            <td class="sl-w-100px sl-border-l-1px-transparent sl-white-space-no-wrap">Annual Cost Savings</td><!-- Gas -->
            <td class="sl-w-100px sl-border-l-1px-transparent"><!-- Water --></td>
            <td class="sl-w-100px"><!-- Electricity (kWh) --></td>
            <td class="sl-w-100px sl-border-l-1px-transparent sl-white-space-no-wrap">Annual Consumption Savings</td><!-- Gas -->
            <td class="sl-w-100px sl-border-l-1px-transparent"><!-- Water (Gal) --></td>
            <td class="sl-w-100px"><!-- Rebate --></td>
            <td class="sl-w-175px"><!-- Category --></td>
            <td class="sl-w-175px sl-border-l-1px-transparent"><!-- System Type --></td>
            <td class="sl-w-175px sl-border-l-1px-transparent"><!-- Project Status --></td>
            <td class="sl-w-110px sl-border-l-1px-transparent"><!-- Install Date --></td>
            <td class="sl-w-110px sl-border-l-1px-transparent"><!-- Due Date --></td>
            <td class="sl-w-60px sl-white-space-no-wrap"><!-- Scenario 1 --></td>
            <td class="sl-w-60px sl-border-l-1px-transparent">Scenario</td><!-- Scenario 2 -->
            <td class="sl-w-60px sl-border-l-1px-transparent"><!-- Scenario 3 --></td>
            <td class="sl-w-400px"><!-- Notes --></td>
          </tr>
          <tr class='ewem-bottom-header'>
            <EwemHeaderCell :data='headers.installed_cost'/>
            <EwemHeaderCell :data='headers.base_installed_cost'/>
            <EwemHeaderCell :data='headers.cost_certainty'/>
            <EwemHeaderCell :data='headers.rul_year'/>
            <EwemHeaderCell :data='headers.annual_cost_savings_total_electricity'/>
            <EwemHeaderCell :data='headers.annual_cost_savings_total_gas'/>
            <EwemHeaderCell :data='headers.annual_cost_savings_water'/>
            <EwemHeaderCell :data='headers.annual_site_energy_savings_total_electricity'/>
            <EwemHeaderCell :data='headers.annual_site_energy_savings_total_gas'/>
            <EwemHeaderCell :data='headers.annual_site_savings_water'/>
            <EwemHeaderCell :data='headers.rebate'/>
            <EwemHeaderCell :data='headers.category' @filter='filter'/>
            <EwemHeaderCell :data='headers.system_type' @filter='filter'/>
            <EwemHeaderCell :data='headers.project_status' @filter='filter'/>
            <EwemHeaderCell :data="headers.install_date"/>
            <EwemHeaderCell :data="headers.due_date"/>
            <EwemHeaderCell :data="headers.scenario1" @click='toggleScenario(1)'/>
            <EwemHeaderCell :data="headers.scenario2" @click='toggleScenario(2)'/>
            <EwemHeaderCell :data="headers.scenario3" @click='toggleScenario(3)'/>
            <EwemHeaderCell :data="headers.notes"/>
          </tr>
        </thead>
        <div v-if="!filteredResults || !ewems.length" class='empty-row'></div>
        <tbody id="ewem_table_main_body">
          <tr v-for="(ewem, index) in ewems"
              :class="{ 'sl-border-b-1px': isLastRow(ewem) }"
              class="sl-table__row sl-table__row--esg"
              :id="`main_body_${ewem.id}`">

            <!-- Installed Cost Column -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.installed_cost"
                     @input="formatValue(ewem, $event)"
                     column="installed_cost" type="text" class="sl-w-85px"/>
            </td>

            <!-- Base Installed Cost -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.base_installed_cost"
                     @input="formatValue(ewem, $event)"
                     column="base_installed_cost" type="text" class="sl-w-85px"/>
            </td>

            <!-- Cost Certainty Column -->
            <td v-if='ewem.show' class="sl-w-175px">
              <select v-model="ewem.cost_certainty"
                      column="cost_certainty"
                      class="sl-w-140px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
                <option v-for="option in costCertaintyOptions">{{ option }}</option>
              </select>
            </td>

            <!-- Remaining Useful Life Year Column -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.rul_year"
                     column="rul_year" type="number" class="sl-w-85px sl-disable-number-input-up-down-arrows"/>
            </td>

            <!-- Annual Cost Savings Electricity Column -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.annual_cost_savings_total_electricity"
                     @input="formatValue(ewem, $event)"
                     column="annual_cost_savings_total_electricity" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Cost Savings Gas Column -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.annual_cost_savings_total_gas"
                     @input="formatValue(ewem, $event)"
                     column="annual_cost_savings_total_gas" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Cost Savings Water Column -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.annual_cost_savings_water"
                      @input="formatValue(ewem, $event)"
                     column="annual_cost_savings_water" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Site Energy Savings Electricity Column -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.annual_site_energy_savings_total_electricity"
                     @input="formatValue(ewem, $event)"
                     column="annual_site_energy_savings_total_electricity" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Site Energy Savings Gas Column -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.annual_site_energy_savings_total_gas"
                     @input="formatValue(ewem, $event)"
                     column="annual_site_energy_savings_total_gas" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Site Savings Water Column -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.annual_site_savings_water"
                     @input="formatValue(ewem, $event)"
                     column="annual_site_savings_water" type="text" class="sl-w-85px"/>
            </td>

            <!-- Rebate -->
            <td v-if='ewem.show' class="sl-w-100px">
              <input v-model="ewem.rebate"
                     @input="formatValue(ewem, $event)"
                     column="rebate" type="text" class="sl-w-85px"/>
            </td>

            <!-- Category -->
            <td v-if='ewem.show' class="sl-w-175px">
              <select v-model="ewem.category" column="category"
                      class="sl-w-140px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
                <option v-for="option in categoryOptions">{{ option }}</option>
              </select>
            </td>

            <!-- System Type -->
            <td v-if='ewem.show' class="sl-w-175px">
              <select v-model="ewem.system_type" column="system_type"
                      class="sl-w-140px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
                <option v-for="option in systemTypeOptions">{{ option }}</option>
              </select>
            </td>

            <!-- Project Status -->
            <td v-if='ewem.show' class="sl-w-175px">
              <select v-model="ewem.status" column="status"
                      class="sl-w-140px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
                <option v-for="option in projectStatusOptions">{{ option }}</option>
              </select>
            </td>

            <!-- Install Date -->
            <td v-if='ewem.show' class="sl-w-110px">
              <input v-model="ewem.install_date"
                    column="install_date" type="date" class="sl-w-100px"/>
            </td>

            <!-- Due Date -->
            <td v-if='ewem.show' class="sl-w-110px">
              <input v-model="ewem.due_date"
                    column="due_date" type="date" class="sl-w-100px"/>
            </td>

            <!-- Scenario 1 -->
            <td v-if='ewem.show' class="sl-w-60px">
              <label :for="`${ewem.id}_scenario_1`" class='sl-custom-checkbox sl-ml-5px sl-mb-20px'>
                <input :id="`${ewem.id}_scenario_1`"
                       @input="formatValue(ewem, $event)"
                       type='checkbox'
                       column='scenario_1'
                       :checked="ewem.scenario_1"/>
                <span class='checkmark'></span>
              </label>
            </td>

            <!-- Scenario 2 -->
            <td v-if='ewem.show' class="sl-w-60px">
              <label :for="`${ewem.id}_scenario_2`" class='sl-custom-checkbox sl-ml-5px sl-mb-20px'>
                <input :id="`${ewem.id}_scenario_2`"
                        @input="formatValue(ewem, $event)"
                        type='checkbox'
                        column='scenario_2'
                        :checked="ewem.scenario_2"/>
                <span class='checkmark'></span>
              </label>
            </td>
            
            <!-- Scenario 3 -->
            <td v-if='ewem.show' class="sl-w-60px">
              <label :for="`${ewem.id}_scenario_3`" class='sl-custom-checkbox sl-ml-5px sl-mb-20px'>
                <input :id="`${ewem.id}_scenario_3`"
                       @input="formatValue(ewem, $event)"
                       type='checkbox'
                       column='scenario_3'
                       :checked="ewem.scenario_3"/>
                <span class='checkmark'></span>
              </label>
            </td>

            <!-- Notes -->
            <td v-if='ewem.show' class="sl-w-400px">
              <textarea v-model="ewem.notes"
                        column="notes"
                        @dblclick="toggleExpand(ewem)"
                        @mouseenter="showExpandTitle($event)"
                        class="sl-w-400px sl-overflow-y-scroll sl-pl-10px"
                        :class="{ expandedTextCell: ewem.expand}">
              </textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>

  <!-- Bottom Buttons -->
  <div class="sl-flex-justify-content-space-btwn sl-mt-20px sl-w-100per">
    <button @click="saveAll" class="sl-simple-outline-btn sl-w-100px">Save</button>
    <button @click="deleteAll" class="sl-simple-outline-btn-warning sl-w-100px">Delete All</button>
  </div>
  <div class='sl-mt-200px'></div>
</template>
<script>
import axios from 'axios';
import Sortable from 'sortablejs';
import SpinningModal from '../../../components/SpinningModal.vue';
import ExcelDropzone from '../../../components/ExcelDropzone.vue';
import EwemHeaderCell from './EwemHeaderCell.vue';
import EwemImportModal from './EwemImportModal.vue';
import deleteIcon from '../../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import plusIcon from '../../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg';
import infoIcon from '../../../../../assets/images/icons/information_circle_icon.svg';
import handle from '../../../../../assets/images/icons/handle.svg';

export default {
  components: {
    SpinningModal,
    ExcelDropzone,
    EwemHeaderCell,
    EwemImportModal,
    deleteIcon,
    plusIcon,
    infoIcon,
    handle,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get('project_id');
    const projectScopeId = urlParams.get('project_scope_id');
    const projectTrackingView = window.location.href.includes('project_tracking');
    return {
      ewems: [],
      headers: {},
      projectId: projectId,
      projectScopeId: projectScopeId,
      projectTrackingView: projectTrackingView,
      costCertaintyOptions: [],
      categoryOptions: [],
      systemTypeOptions: [],
      projectStatusOptions: [],
      filteredResults: true,
      selectedCategory: null,
      selectedSystemType: null,
      selectedProjectStatus: null,
      baselineYear: null,
      maxDecarbYear: null,
      maxRowsAllowed: 50,
      showLoadingModal: false,
      alertMessage: '',
      dataReady: false,
      secondaryFuelType: {},
      showNotesContainer: false,
      rowsToAdd: 1,
      showImportModal: false,
      componentKey: 0,
    };
  },

  async created() {
    this.fetchEwems();
  },

  methods: {
    async fetchEwems() {
      await axios.get('/ewems/vue_index', {
        params: {
          project_id: this.projectId,
        },
      })
        .then((response) => {
          this.ewems = response.data.ewems;
          this.costCertaintyOptions = response.data.cost_certainty_options;
          this.categoryOptions = response.data.category_options;
          this.systemTypeOptions = response.data.system_type_options;
          this.projectStatusOptions = response.data.project_status_options;
          this.baselineYear = response.data.baseline_year;
          this.maxDecarbYear = response.data.max_decarb_year;
          this.secondaryFuelType = response.data.secondary_fuel_type;
          this.setHeaderData(response.data.headers);
          setTimeout(() => { this.setSortable() }, 500);
          this.dataReady = true;
        })
        .catch(() => {
          this.showErrorNotice();
        });
    },

    async saveAll() {
      this.showLoadingModal = true;
      await axios.put('/ewems/vue_update', {
        ewems: this.ewems,
      })
        .then(() => {
          setTimeout(() => {
            this.showLoadingModal = false;
            this.flashSaveNotice();
          }, 300);
        })
        .catch(() => {
          setTimeout(() => {
            this.showLoadingModal = false;
            this.showErrorNotice();
          }, 300);
        });
    },

    async createEwem() {
      if (this.checkNumberOfRows()) {
        await axios.post('/ewems/vue_create', null, {
          params: {
            project_id: this.projectId,
            number_of_rows: this.rowsToAdd,
          },
        })
          .then((response) => {
            this.ewems = this.ewems.concat(response.data.ewems);
            this.ewems.sort((a, b) => a.index_number - b.index_number);
            // Force the component to re-render
            this.componentKey += 1;
            this.rowsToAdd = 1;
            setTimeout(() => { this.setSortable() }, 300);
          })
          .catch(() => {
            this.showErrorNotice();
          })
          .finally(() => {
            this.tableChanged();
          });
      }
    },

    async deleteEwem(ewem) {
      if (this.ewemIsEmpty(ewem) || confirm(`Are you sure want to delete "${ewem.description}"?`)) {
        // Remove the ewem from the array
        this.ewems = this.ewems.filter((e) => e.id !== ewem.id);
        // Reorder the ewems based on the index_number        
        this.ewems.sort((a, b) => a.index_number - b.index_number);
        // Update the index_number field to fill in the gap
        this.ewems.forEach((e, index) => {
          e.index_number = index + 1;
        });
        // Force the component to re-render, this is needed to keep the elements in the correct order in the html table
        this.componentKey += 1;
        setTimeout(() => { this.setSortable() }, 300);
        // Delete the ewem from the database
        await axios.delete('/ewems/vue_destroy', {
          params: {
            id: ewem.id,
            project_id: this.projectId,
          },
        })
          .catch(() => {
            this.showErrorNotice();
          })
          .finally(() => {
            this.tableChanged();
          });
      }
    },

    // Delete all ewems on a project
    async deleteAll() {
      if (confirm('Are you sure want to delete all Ewems for this project?')) {
        this.showLoadingModal = true;
        await axios.delete('/ewems/vue_destroy_all', {
          params: {
            project_id: this.projectId,
          },
        })
          .then(() => {
            this.ewems = [];
            setTimeout(() => {
              this.showLoadingModal = false;
            }, 300);
          })
          .catch(() => {
            this.showErrorNotice();
            this.showLoadingModal = false;
          })
          .finally(() => {
            this.tableChanged();
          });
      }
    },

    // Check the number of rows before adding a new row
    checkNumberOfRows() {
      if ((this.ewems.length + this.rowsToAdd) <= this.maxRowsAllowed) {
        return true;
      }
      alert('The maximum number of rows to add is 50');
      return false;
    },

    // Format number as currency or number with delimeter
    formatValue(ewem, event) {
      const column = event.target.getAttribute('column');
      let { value } = event.target;

      if (['installed_cost', 'base_installed_cost', 'annual_cost_savings_total_electricity',
        'annual_cost_savings_total_gas', 'annual_cost_savings_water', 'rebate'].includes(column)) {
        value = value.replace(/[^\d.-]/g, ''); // Replace non-numerical characters
        ewem[column] = this.numberToCurrency(value);
      } else if (['annual_site_energy_savings_total_electricity',
        'annual_site_energy_savings_total_gas', 'annual_site_savings_water'].includes(column)) {
        value = value.replace(/[^\d.-]/g, ''); // Replace non-numerical characters
        ewem[column] = this.numberWithDelimiter(value);
      } else if (['scenario_1', 'scenario_2', 'scenario_3'].includes(column)) {
        value = event.target.checked;
        ewem[column] = value;
      }
      return value;
    },

    // Return true if the ewem is the last row on the table
    isLastRow(ewem) {
      return ewem.index_number === this.ewems.length;
    },

    // Return true if the ewem does not have a description
    ewemIsEmpty(ewem) {
      return (ewem.description === null || ewem.description === '');
    },

    numberToCurrency(number) {
      return `$${Intl.NumberFormat().format(Math.round(number))}`;
    },

    numberWithDelimiter(number) {
      return Intl.NumberFormat().format(Math.round(number));
    },

    flashSaveNotice() {
      this.alertMessage = 'Table Saved';
    },

    showErrorNotice() {
      this.alertMessage = 'An error has occurred and the data entered has not been saved';
    },

    uploadComplete() {
      this.showImportModal = false;
      this.fetchEwems();
      this.alertMessage = 'Import Complete';
      setTimeout(() => {
        this.alertMessage = '';
      }, 2000);
    },

    toggleExpand(ewem) {
      ewem.expand = !ewem.expand;
    },
    
    async toggleScenario(scenario) {
      const allEmpty = this.ewems.every((ewem) => !ewem[`scenario_${scenario}`]);
      const boolean = allEmpty ? true : false;
      this.ewems.forEach((ewem) => {
        ewem[`scenario_${scenario}`] = boolean;
      });
    },

    setSortable() {
      const el = document.getElementById('pl_table_body');
      if (el) {
        Sortable.create(el, {
          handle: '.sortable-handle',
          animation: 150,
          ghostClass: 'sortable-ghost',
          onEnd: () => {
            this.tableChanged();
            this.updateSortOrder();
          },
        });
      }
    },

    // Loop through elements in the table and update the index_number field
    async updateSortOrder() {
      const el = document.getElementById('pl_table_body');
      let index = 1;
      let sortedIds = [];
      for (let row of el.children) { // eslint-disable-line no-restricted-syntax, prefer-const
        const htmlRow = this.ewems.find((r) => r.id == row.id); // eslint-disable-line eqeqeq
        if (htmlRow) {
          htmlRow.index_number = index;
          index++;
          sortedIds.push(htmlRow.id);
        }
      }
      
      // Update the main table and delete column, these are seperate html tables
      const mainTableBody = document.getElementById('ewem_table_main_body');
      let sortedBodyRows = [];
      for (let id of sortedIds) {
        const htmlRow = document.getElementById(`main_body_${id}`);
        if (htmlRow) {
          sortedBodyRows.push(htmlRow);
        }
      }
      for (let row of sortedBodyRows) {
        mainTableBody.appendChild(row);
      }
      let sortStatus = [];
      for (let ewem of this.ewems) {
        sortStatus.push({
          id: ewem.description,
          index_number: ewem.index_number,
        });
      }
    },

    // Show this message next to the pointer after a delay
    showExpandTitle(event) {
      setTimeout(() => {
        event.target.title = 'Double click to expand';
      }, 1000);
    },

    // Hide and show ewems based on the selected filters
    filter(header, value) {
      // Remove spaces from the header to use as the attribute name e.g. 'System Type' -> 'selectedSystemType'
      const attribute = `selected${header.replace(/\s/g, '')}`;
      this[attribute] = value;
      const filterAttributes = {
        category: this.selectedCategory,
        system_type: this.selectedSystemType,
        status: this.selectedProjectStatus
      }

      this.ewems.forEach((ewem) => {
        ewem.show = true;
        for (const [key, value] of Object.entries(filterAttributes)) {
          if (value && ewem[key] !== value) {
            ewem.show = false;
          }
        }
      });
      // Set the filteredResults to true if there are any ewems that are shown
      this.filteredResults = this.ewems.filter((ewem) => ewem.show).length > 0;
    },

    setHeaderData(headers) {
      this.headers = headers;
      // If a secondary fuel type is selected as something other than the default 'Natural Gas', update the applicable header and tooltip text
      if (this.secondaryFuelType.display_name !== 'Natural Gas' && this.secondaryFuelType.unit_of_measure !== 'Therms') {
        const secondaryFuelType = this.secondaryFuelType.display_name;
        const secondaryFuelTypeLowercase = secondaryFuelType.toLowerCase();
        const unitOfMeasure = this.secondaryFuelType.unit_of_measure;
        this.headers.annual_cost_savings_total_gas.header = secondaryFuelType;
        this.headers.annual_site_energy_savings_total_gas.header = `${secondaryFuelType}<br>(${unitOfMeasure})`;
        this.headers.annual_cost_savings_total_gas.tooltip_text = `The amount of money saved on ${secondaryFuelTypeLowercase} costs<br>over the course of a year`;
        this.headers.annual_site_energy_savings_total_gas.tooltip_text = `The amount of ${secondaryFuelTypeLowercase} saved over a year`;
      }
      // Set the options for the category, system type, and status filters
      this.headers.category.options = this.categoryOptions;
      this.headers.system_type.options = this.systemTypeOptions;
      this.headers.project_status.options = this.projectStatusOptions;
    },

    tableChanged() {
      this.alertMessage = '';
    },
  },
};
</script>
<style scoped>
  thead tr:first-child {
    border-bottom: 0 !important;
  }

  textarea {
    height: 40px;
    transition: height 0.3s;
  }


  #delete-column-header tr:first-child td {
    border-left: 1px solid #9F9EA4;
  }

  .ewem-notes-container {
    background-color: white;
    border: 1px solid #9F9EA4;
    height: 200px;
    width: 360px;
    margin: 10px 0;
    padding: 5px 10px;
  }

  .expandedTextCell {
    height: 200px;
    transition: height 0.3s;
  }

  .sortable-ghost {
    opacity: 0.4;
  }

  .empty-row {
    height: 44px;
    background-color: #E9E9E9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding-left: 100px
  }
</style>
