<template>
  <form @submit.prevent='sendProjectEmail' enctype="multipart/form-data">
    <input type="hidden" name="id" :value="project_id">
    <input type="hidden" name="foia_description" v-if="projectdoc_foia_description"
      :value="projectdoc_foia_description">
    <input type="hidden" name="report_type" v-if="projectdoc_foia" value="Zoning">
    <input type="hidden" name="foia_doc_id" v-if="projectdoc_foia_id" :value="projectdoc_foia_id">
    <input type="hidden" name="type_of_document" v-if="type_of_document" :value="type_of_document">
    <input type="hidden" name="uploaded_document_link" v-if="uploaded_document_link" :value="uploaded_document_link">

    <div class="pl-page-container">
      <div class='sl-blue-section-header-bar sl-font-size-16px'><span class='sl-p-all-10px'>Create your email
          below.</span></div>
      <!-- Email form fields -->

      <!-- To Address -->
      <div class="sl-flex-justify-content-center sl-pt-10px sl-font-size-16px">
        <div class="sl-input-label">To:</div>
        <textarea v-model="to_address" id="email_to_address_field" class="sl-email-inputs" rows="1"></textarea>
      </div>

      <!-- Cc Address -->
      <div class="sl-flex-justify-content-center sl-pt-10px sl-font-size-16px">
        <div class="sl-input-label">Cc:</div>
        <textarea v-model="cc_address" id="email_cc_address_field" class="sl-email-inputs"></textarea>
      </div>

      <!-- From Address -->
      <div class="sl-flex-justify-content-center sl-pt-10px sl-font-size-16px">
        <div class="sl-input-label">From:</div>
        <textarea v-model="default_email_from_address" id="email_from_address_field" class="sl-email-inputs"
          disabled></textarea>
      </div>

      <!-- Subject Address -->
      <div class="sl-flex-justify-content-center sl-pt-10px sl-font-size-16px">
        <div class="sl-input-label">Subject:</div>
        <textarea v-model="subject" id="email_title_field" class="sl-email-inputs"></textarea>
      </div>

      <!-- Body Address -->
      <div class="sl-flex-justify-content-center sl-pt-10px sl-font-size-16px">
        <div class="sl-input-label">Body:</div>
        <textarea v-model="body" id="email_body_field" class="sl-email-inputs" rows="8"></textarea>
      </div>

      <br>
      <div>
        <hr class='sl-hr-line'>
      </div>
      <br>

      <div class="sl-flex-justify-content-center sl-pt-10px sl-font-size-16px">
        <div class="sl-input-label">
          Body
          <br>
          Continued:
        </div>
        <div class="sl-email-inputs sl-readonly-text sl-font-size-16px">
          <div v-if="selectedProjectdocs.length > 0">
            <table class='sl-mt-10px sl-mb-10px sl-border-b-2px sl-w-100per' style='border-spacing: 0'>
              <thead>
                <tr class='sl-h-28px'>
                  <td class='sl-border-tb-2px-primary-blue sl-bold-text'>Name</td>
                  <td class='sl-border-tb-2px-primary-blue sl-bold-text'>Link</td>
                  <td class='sl-border-tb-2px-primary-blue'></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(doc, index) in selectedProjectdocs" class='sl-table__row--striped sl-h-28px'>
                  <td>{{ doc.doc_file_name }}</td>
                  <td><a :href="doc.direct_doc_link" target="_blank">Download</a></td>
                  <td class='figma-icon-delete'><trash @click="deleteDocument(index)"></trash></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="uploaded_document_link">
            Click <a :href="uploaded_document_link">here</a> to download the document
          </div><br>
          <div><strong>Project summary for the project:</strong> {{ name || '' }}, {{ project_number_string || '' }}
          </div>
          <div><strong>Property Location:</strong> {{ address || '' }}, {{ city || '' }}, {{ state || '' }}</div>
          <div><strong>Scope(s) ordered:</strong>
            <ul class="sl-ul-original">
              <li v-for="scope in project_scopes" :v-if="scope.scope_description.trim() !== ''"
                class="sl-li-original sl-font-size-16px">
                {{ scope.scope_description.trim() }}
                <span :v-if="scope.turnaround.trim() !== ''">
                  - {{ scope.turnaround.trim() }}
                </span>
              </li>
            </ul>
          </div>
          <div><strong>Project Status:</strong> {{ the_status }}</div>
          <div><strong>Property Type:</strong> {{ property_type || '' }}</div>
          <br>
          <div v-if="!no_link">To view the details of your project in Partner SiteLynx Technology, <a :href="url">click
              here</a>
          </div>
          <br>
          <div>Regards,<br>{{ human_name || '' }}</div>
          <br>
          <div>
            <img :src=partnerLogo style='width: 170px;'>
          </div>
          <div class="sl-attachments-div"></div>
        </div>
      </div>
    </div>

    <hr class="sl-hr-line">
    <div class="sl-flex-justify-content-center sl-font-size-16px">
      <div class="sl-input-label"></div>
      <div class="sl-email-inputs sl-readonly-text sl-font-size-16px">
        <div v-if="supplemental_documents.length > 0" class="sl-flex-only" >
          <label>Supplemental Document Uploads:</label>
          <select v-model="projectdocSelectedItem">
            <option disabled value="">Select Document</option>
            <option v-for="doc in supplemental_documents" :value="doc" :disabled="disableDocumentAdd(doc)">{{ doc.doc_file_name }}</option>
          </select>
          <button type="button" class="sl-simple-outline-btn" style="width:140px;" @click="addSelectedProjectdoc">ADD TO EMAIL</button>
        </div>
        <br>
        <span v-if="staff || is_admin">
          <div>
            <input type="checkbox" v-model="bool_pa" id="email_bool_pa_field"
              class="sl-checkbox sl-checkbox-partner-blue"> External PA Permission
            <input type="checkbox" v-model="bool_client" id="email_bool_client_field"
              class="sl-checkbox sl-checkbox-partner-blue"> Client Permission
          </div>
        </span>
        <br>
        <div class="sl-flex-only">
          <button type='submit' class="sl-simple-outline-btn" style="width:140px;">SEND EMAIL</button>
          <span v-if="projectdoc_foia">
            &nbsp;&nbsp;&nbsp;&nbsp;<a :href="'/zoning_data_items/zoning_data?id=' + project_id + '&project_scope_id=' + project_scope_id + '&section_3=true'"
              class="sl-gray-outline-btn">DON'T SEND EMAIL</a>
          </span>
          <span v-else>
            &nbsp;&nbsp;&nbsp;&nbsp;<a :href="'/projects/' + project_id" class="sl-gray-outline-btn">DON'T SEND
              EMAIL</a>
          </span>
          <attachmentIcon class="sl-attachment-btn sl-ml-10px" title="Attach File" @click="OpenFileUpload" />
          <input type="file" multiple class="sl-file-field" accept=".pdf, .doc, .docx, .csv, .xls, .xlsx"
            name="files_uploaded" id="files_uploaded_" @change="DisplayFiles">
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import partnerLogo from '../../../assets/partner_logo_white_cropped.jpg';
import attachmentIcon from '../../../../assets/images/icons/Icon-Attachment_Charcoal-Dark-100_20.svg';
import trash from "../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg"
import xIcon from '../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_16.svg?url'; // special case for xIcon. "?url" is added so it can be used in the img src in JS

export default {
  components: {
    trash,
    attachmentIcon
  },

  data() {
    return {
      xIcon: xIcon,
      project_id: null,
      default_email_from_address: null,
      name: null,
      project_number_string: null,
      address: null,
      city: null,
      state: null,
      project_scopes: [],
      the_status: null,
      property_type: null,
      human_name: null,
      staff: null,
      is_admin: null,
      projectdoc_foia: null,
      projectdoc_foia_description: null,
      projectdoc_foia_id: null,
      type_of_document: null,
      uploaded_document_link: null,
      to_address: null,
      cc_address: null,
      subject: null,
      body: null,
      no_link: null,
      url: null,
      bool_pa: null,
      bool_client: null,
      supplemental_documents: [],
      projectdocSelectedItem: null,
      selectedProjectdocs: [],
      files_uploaded: [],
      totalSizeMb: 0,
      partnerLogo,
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const project_id = urlParams.get('id');
    const project_scope_id = urlParams.get('project_scope_id');
    const draft = urlParams.get('draft');
    const final = urlParams.get('final');
    const draw = urlParams.get('draw');
    const general = urlParams.get('general');
    const invoice = urlParams.get('invoice');
    const the_body = urlParams.get('the_body');
    const bool_pa = urlParams.get('bool_pa');
    const foia_doc = urlParams.get('foia_doc');
    const foia_email = urlParams.get('foia_email');
    const email_field_name = urlParams.get('email_field_name');
    const foia_desc = urlParams.get('foia_desc');
    const report_type = urlParams.get('report_type');
    const foia_document = urlParams.get('foia_document');
    const doc_name = urlParams.get('doc_name');

    await axios.get(`/projects/return_to_send_email_data`, {
      params: {
        project_id,
        project_scope_id,
        draft,
        final,
        draw,
        general,
        invoice,
        the_body,
        bool_pa,
        foia_doc,
        foia_email,
        email_field_name,
        foia_desc,
        report_type,
        foia_document,
        doc_name,
      },
    })
      .then((response) => {
        this.project_id = project_id;
        this.project_scope_id = project_scope_id;
        this.default_email_from_address = response.data.default_email_from_address;
        this.name = response.data.name;
        this.project_number_string = response.data.project_number_string;
        this.address = response.data.address;
        this.city = response.data.city;
        this.state = response.data.state;
        this.project_scopes = response.data.project_scopes;
        this.the_status = response.data.the_status;
        this.property_type = response.data.property_type;
        this.human_name = response.data.human_name;
        this.staff = response.data.staff;
        this.is_admin = response.data.is_admin;
        this.projectdoc_foia = response.data.projectdoc_foia;
        this.projectdoc_foia_description = response.data.projectdoc_foia_description;
        this.projectdoc_foia_id = response.data.projectdoc_foia_id;
        this.type_of_document = response.data.type_of_document;
        this.uploaded_document_link = response.data.uploaded_document_link;
        this.to_address = response.data.to_address;
        this.cc_address = response.data.cc_address;
        this.subject = response.data.subject;
        this.body = response.data.body;
        this.no_link = response.data.no_link;
        this.url = response.data.url;
        this.bool_pa = response.data.bool_pa;
        this.bool_client = response.data.bool_client;
        this.supplemental_documents = response.data.supplemental_documents;
      }).catch((error) => {
        console.log(error);
      });

    // Event listener for file deletion
    $jq(document).on("click", ".sl-delete-file", this.DeleteFile);
  },

  methods: {
    async sendProjectEmail() {
      const formData = new FormData();
      formData.append('project_id', this.project_id);
      formData.append('body', this.body);
      formData.append('title', this.subject);
      formData.append('foia_description', this.projectdoc_foia_description);
      formData.append('foia_doc_id', this.projectdoc_foia_id);
      formData.append('report_type', this.report_type);
      formData.append('type_of_document', this.type_of_document);
      formData.append('uploaded_document_link', this.uploaded_document_link);
      formData.append('to_address', this.to_address);
      formData.append('cc_address', this.cc_address);
      formData.append('from_address', this.default_email_from_address);
      // Serialize the JavaScript object
      formData.append('selected_projectdocs', JSON.stringify(this.selectedProjectdocs));

      // Append each file to FormData
      for (let i = 0; i < this.files_uploaded.length; i++) {
        formData.append('files_uploaded[]', this.files_uploaded[i]);
      }

      await axios.post('/project_emails/send_project_email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        if (this.report_type === 'Zoning') {
          window.location.href = `/zoning_data_items/zoning_data?id=${this.project_id}&project_scope_id=${this.project_scope_id}&section_3=true`;
        } else {
          window.location.href = `/projects/${this.project_id}`;
        }
      }).catch((error) => {
        console.log(error);
      });
    },

    OpenFileUpload(e) {
      e.preventDefault();
      const openFiles = document.getElementById('files_uploaded_');
      openFiles.click();
    },

    DisplayFiles(e) {
      var files = e.target.files;
      //Append a div with file name for each file uploaded   
      for (let i = 0; i < files.length; i++) {
        console.log(files[i].size)
        var fileSizeKb = Math.round(files[i].size / (1024));
        this.totalSizeMb += fileSizeKb / 1024;
        console.log(this.totalSizeMb);
        if (this.totalSizeMb > 20) {
          alert("File size too large")
          this.totalSizeMb -= fileSizeKb / 1024
          console.log(this.totalSizeMb)
          break;
        } else {
          var ximage = $jq("<img class='sl-delete-file sl-pr-10px'>").attr("src", xIcon).attr("data-file-index", files[i].name);
          var fileDiv = $jq(`<h3 class='sl-attachment sl-font-size-16px sl-p-all-10px sl-flex-only'>${files[i].name} - ${fileSizeKb} KB</h3>`);
          fileDiv.prepend(ximage);
          $jq('.sl-attachments-div').prepend(fileDiv);
          // Append the file to the files_uploaded array
          this.files_uploaded.push(files[0]);
        }
      }
    },

    DeleteFile(e) {
      e.preventDefault();
      const fileName = e.target.getAttribute("data-file-index");
      // Find the index of the file to delete
      const indexToDelete = this.files_uploaded.findIndex(file => file.name === fileName);

      if (indexToDelete !== -1) {
        // Get the file size in KB
        const fileSizeKb = Math.round(this.files_uploaded[indexToDelete].size / (1024));
        // Remove the file from the array
        this.files_uploaded.splice(indexToDelete, 1);
        // Update totalSizeMb
        this.totalSizeMb -= fileSizeKb / 1024;
        console.log('totalSizeMb after delete: ', this.totalSizeMb);
      }
      // Remove the file element from the DOM
      e.target.parentNode.remove();
    },

    addSelectedProjectdoc() {
      if (this.projectdocSelectedItem) {
        // Check if the selected document is already in the selectedProjectdocs array
        if (!this.selectedProjectdocs.some(doc => doc.doc_file_name === this.projectdocSelectedItem.doc_file_name)) {
          // If not, add it to the array
          this.selectedProjectdocs.push(this.projectdocSelectedItem);
          // Update the body of the email
          this.body = 'Hello,\n\nThe following documents were uploaded for this project in Sitelynx:\n';
        }
        // Reset the projectdocSelectedItem
        this.projectdocSelectedItem = null;
      }
    },

    disableDocumentAdd(doc) {
      // set projectdoc dropdown value to disabled if it is already added to selectedProjectdocs
      return this.selectedProjectdocs.some(selectedDoc => selectedDoc.doc_file_name === doc.doc_file_name);
    },

    deleteDocument(index) {
      // Remove the document from the selectedProjectdocs array
      this.selectedProjectdocs.splice(index, 1);
    }
  }
};
</script>

<style>
.sl-attachment-btn:hover {
  filter: brightness(0) saturate(100%) invert(16%) sepia(77%) saturate(3078%) hue-rotate(194deg) brightness(98%) contrast(102%);
  cursor: pointer;
}
</style>
