<template>
  <CollapsibleSection :barText="'Tenants'"
                      :toggleHeaderColor=false
                      :bottomBorder=false
                      class='sl-mb-20px'>
    <table class='sl-simple-table-1 sl-border-b-2px sl-mb-10px sl-text-align-left sl-font-size-14px'>
      <thead>
        <tr class='sl-border-b-2px-blue sl-h-37px sl-bold-text sl-border-t-0'>
          <td class='sl-w-25per sl-pl-10px'>Name</td>
          <td class='sl-w-25per sl-pl-5px'>Square Footage</td>
          <td class='sl-w-25per'>Meters</td>
          <td class='sl-w-25per sl-text-align-right'>
            <div class='sl-tooltip'>
              <button v-if='userIsStaff' @click='addTenant'
                      :class="{'sl-simple-outline-btn-disabled':disbaleAddTenant()}"
                      class='sl-simple-outline-btn'>Add Tenant</button>
              <span class='sl-tooltiptext'>Staff Only</span>
            </div>
          </td>
        </tr>
      </thead>
      <tbody v-if='tenants.length' style='color: #333E47'>
        <tr v-for='(tenant, index) in tenants' class='sl-h-42px'>
          <td class='sl-w-25per sl-pl-10px'>
            <input v-if='tenant.edit || tenant.new' v-model='tenant.name'
                   class='sl-border-1px-medium-charcoal sl-pl-5px sl-ptb-5px sl-w-270px'
                   type='text'
                   placeholder="Enter the tenant name here..."/>
            <span v-else>{{ tenant.name }}</span>
          </td>
          <td class='sl-w-25per sl-pl-5px'>
            <input v-if='tenant.edit || tenant.new' v-model='tenant.square_footage'
                  type='number'
                  class='sl-border-1px-medium-charcoal sl-pl-5px sl-ptb-5px
                         sl-w-150px sl-disable-number-input-up-down-arrows'
                  placeholder="0"/>
            <span v-else>{{ numberWithDelimiter(tenant.square_footage) }}</span>
          </td>
          <td class='sl-ptb-10px sl-w-25per'>
            <div v-for='(meter, meterIndex) in tenant.meters' class='sl-ptb-5px sl-w-300px sl-flex-justify-content-space-btwn'>
              <a :href='meterUrl(meter)' class='sl-blue-link'>{{ meter.name }}</a>
              <a v-if='tenant.edit' @click='removeMeter(tenant, meter, meterIndex)' class='sl-blue-link pl-circle-edit-btn sl-tooltip'>
                <clearIcon class='figma-icon sl-mr-10px'/>
                <span class='sl-tooltiptext'>Remove Meter</span>
              </a>
            </div>
            <div v-if='tenant.edit || tenant.new'>
              <select v-if='newMeters.length' v-for='(newMeter, newMeterIndex) in newMeters'
                      v-model='this.newMeters[newMeterIndex]'
                      class='sl-border-1px-medium-charcoal sl-w-250px sl-ptb-5px sl-mb-10px'
                      style='display: block;'>
                <option :value=null selected style='color: #9F9EA4;'>Choose a Meter</option>
                <option v-for='meter in meters' :value='meter.id'>{{ meter.name }}</option>
              </select>
              <div class='sl-flex-align-items-center'>
                <a @click='addMeter' class='sl-blue-link sl-bold-text sl-font-size-12px'><span>Add Meter to Tenant</span></a>
              </div>
            </div>
          </td>

          <td class='sl-flex-justify-content-end'>
            <div v-if='tenant.new' class='sl-flex-justify-content-space-btwn sl-ptb-10px'>
              <button @click='createTenant(tenant)' class='sl-simple-outline-btn sl-w-85px sl-mr-10px sl-h-28px'>Save</button>
              <button @click='cancelCreate(index)' class='sl-simple-outline-btn-warning sl-w-85px sl-h-28px'>Cancel</button>
            </div>
            <div v-if='tenant.edit' class='sl-flex-justify-content-space-btwn sl-ptb-10px'>
              <button @click='updateTenant(tenant, index)' class='sl-simple-outline-btn sl-w-85px sl-mr-10px sl-h-28px'>Save</button>
              <button @click='cancelUpdate(index)' class='sl-simple-outline-btn sl-w-85px sl-mr-10px sl-h-28px'>Cancel</button>
            </div>
            <div v-else-if='showEditButtons'
                class='sl-ptb-10px sl-w-60px sl-pr-10px
                       sl-flex-align-items-center sl-flex-justify-content-space-btwn'>
              <div class='sl-tooltip'>
                <editIcon v-if='userIsStaff' @click='editTenant(tenant)' transform='scale(0.9)' class='figma-icon'/>
                <span class='sl-tooltiptext'>Edit Tenant (Staff Only)</span>
              </div>
              <div class='sl-tooltip'>
                <deleteIcon v-if='userIsStaff' @click='deleteTenant(tenant, index)' transform='scale(0.7)' class='figma-icon'/>
                <span class='sl-tooltiptext'>Delete Tenant (Staff Only)</span>
              </div>
            </div>
          </td>
        </tr>
        <tr class='sl-h-42px sl-border-t-2px-primary-blue'>
          <td class='sl-w-200px sl-pl-10px'>Totals:</td>
          <td class='sl-w-200px sl-pl-5px'>
            {{ `${totalTenantSquareFootage()} of ${totalPropertySquareFootage()} sq. ft.` }}
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div v-if='!tenants.length' class='sl-font-size-14px sl-h-84px sl-flex-justify-content-and-align-items-center sl-border-b-2px'>
      There are no tenants found for this property.
    </div>
    <br>
  </CollapsibleSection>
</template>
<script>
import axios from 'axios';
import { cloneDeep as _cloneDeep } from 'lodash';
import CollapsibleSection from '../../../components/CollapsibleSection.vue';
import editIcon from '../../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import deleteIcon from '../../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import plusIcon from '../../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg';
import clearIcon from '../../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_16.svg';

export default {
  components: {
    CollapsibleSection,
    editIcon,
    deleteIcon,
    plusIcon,
    clearIcon,
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      property: null,
      tenants: [],
      meters: [],
      newMeters: [],
      removeMeters: [],
      tenantBeforeEdit: null,
      showEditButtons: true,
      notice: [],
      userIsStaff: false,
      fetchingTenants: false,
    };
  },

  mounted() {
    this.fetchTenants();
  },

  methods: {
    async fetchTenants() {
      this.fetchingTenants = true;
      await axios.get('/espm_tenants/vue_index', {
        params: {
          property_id: this.propertyId,
          project_id: this.projectId,
        },
      })
        .then((response) => {
          this.property = response.data.property;
          this.tenants = response.data.tenants;
          this.meters = response.data.meters;
          this.userIsStaff = response.data.user_is_staff;
          setTimeout(() => { this.fetchingTenants = false; }, 500);
        })
        .catch(() => {
          this.fetchingTenants = false;
          alert('Something went wrong. Please reach out to admin@sitelynx.net for support.');
        })
        .finally(() => {
          this.$emit('tenant-data-ready');
        });
    },

    // Create a new tenant record
    async createTenant(tenant) {
      this.fetchingTenants = true;
      await axios.post('/espm_tenants', null, {
        params: {
          espm_tenant: tenant,
          new_meters: this.newMeters,
        },
      })
        .then((response) => {
          const lastIndex = this.tenants.length - 1;
          this.tenants[lastIndex] = response.data.tenant;
          this.newMeters = [];
          this.showEditButtons = true;
          setTimeout(() => { this.fetchingTenants = false; }, 500);
          this.notice = response.data.notice;
          setTimeout(() => { this.notice = []; }, 5000);
        })
        .catch((error) => {
          this.showEditButtons = true;
          this.fetchingTenants = false;
          this.notice = error.response.data.notice;
        });
    },

    // Update a tenant record
    async updateTenant(tenant, index) {
      this.fetchingTenants = true;
      await axios.put(`/espm_tenants/${tenant.id}`, null, {
        params: {
          espm_tenant: tenant,
          new_meters: this.newMeters,
          remove_meters: this.removeMeters,
        },
      })
        .then((response) => {
          this.tenants[index] = response.data.tenant;
          this.meters = response.data.meters;
          this.newMeters = [];
          this.removeMeters = [];
          this.showEditButtons = true;
          setTimeout(() => { this.fetchingTenants = false; }, 500);
          this.notice = response.data.notice;
          setTimeout(() => { this.notice = []; }, 5000);
        })
        .catch((error) => {
          this.showEditButtons = true;
          this.fetchingTenants = false;
          this.notice = error.response.data.notice;
        });
    },

    // Delete a single tenant record
    async deleteTenant(tenant, index) {
      if (window.confirm(`Are you sure you want to delete ${tenant.name} tenant?`)) {
        this.fetchingTenants = true;
        await axios.delete(`/espm_tenants/${tenant.id}`)
          .then(() => {
            this.tenants.splice(index, 1);
            setTimeout(() => { this.fetchingTenants = false; }, 500);
          })
          .catch(() => {
            this.fetchingTenants = false;
            alert('Something went wrong. Please reach out to admin@sitelynx.net for support.');
          });
      }
    },

    // Make a new tenant object to add values into before saving
    addTenant() {
      this.showEditButtons = false;
      this.tenants.push({ espm_property_id: this.property.id, square_footage: 0, new: true });
    },

    editTenant(tenant) {
      // Make a copy of values before edit
      this.tenantBeforeEdit = _cloneDeep(tenant);
      this.showEditButtons = false;
      tenant.edit = true; // eslint-disable-line  no-param-reassign
    },

    // Cancel the edit and go back to values before edit
    cancelUpdate(index) {
      this.tenants[index] = this.tenantBeforeEdit;
      this.newMeters = [];
      this.removeMeters = [];
      this.showEditButtons = true;
      this.tenantBeforeEdit = null;
      this.notice = [];
    },

    // Cancel creating a new tenant
    cancelCreate(index) {
      this.tenants.splice(index, 1);
      this.showEditButtons = true;
      this.notice = [];
    },

    // Remove meter from tenant
    removeMeter(tenant, meter, index) {
      tenant.meters.splice(index, 1);
      this.removeMeters.push(meter.id);
    },

    // Add a meter to the tenant
    addMeter() {
      if (!this.meters.length) {
        alert('There are no available meters to add to this tenant.');
      }
      if (this.newMeters.length < this.meters.length) {
        this.newMeters.push(null);
      }
    },

    // Show the add meter button when the tenant is in edit view
    showAddMeterBtn(tenant) {
      return tenant?.meters?.length < this.meters?.length;
    },

    // Calculate the total square footage of all tenants
    totalTenantSquareFootage() {
      let totalSquareFootage = 0;
      this.tenants.forEach((tenant) => {
        totalSquareFootage += tenant.square_footage;
      });
      return this.numberWithDelimiter(totalSquareFootage);
    },

    // Return the total square footage of the property
    totalPropertySquareFootage() {
      return this.numberWithDelimiter(this.property.gross_floor_area);
    },

    numberWithDelimiter(number) {
      return Intl.NumberFormat().format(Math.round(number));
    },

    // Disable the add tenant button if the table is loading or the table is in edit mode
    disbaleAddTenant() {
      return this.fetchingTenants || !this.showEditButtons;
    },

    // Return the url for the meter
    meterUrl(meter) {
      if (this.projectId) {
        return `/espm_meters/project_specific?id=${meter.id}&project_id=${this.projectId}`;
      }
      return `/espm_meters/${meter.id}`;
    },
  },
};
</script>
