<template>
  <SpinningModal v-if='showLoading'/>
  <div class='sl-flex-justify-content-center sl-h-15px sl-bold-text sl-font-size-14px'
       style='color: #F04C25'>
       {{ alertMessage }}
  </div>
  <div class='pl-page-container'>
    <h2 class='sl-static-h2-style' style='line-height:20px'>
    <span v-if='!mapSaved' class='sl-blue-text'>&nbsp*</span><!-- Placeholder to keep spacing on the left -->
    <span>{{ documentType }}</span>
    <span v-if='!mapSaved'>&nbsp*</span></h2>
  </div>
  <br>
  <div v-if='dataReady' class='sl-flex-justify-content-center'>
    <!-- Left Side Pannel -->
    <div class='sl-flex-column sl-flex-align-items-center sl-mr-20px'>
      <div class='sl-w-100per'>
        <div class='sl-border-t-1px sl-border-b-1px' id='drawing_tools'>
          <div class='sl-flex-justify-content-center sl-mtb-10px'>
            <div @click="setDrawingMode('POINTER')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='POINTER'}">
              <pointerIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('POLYLINE')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='POLYLINE'}">
              <lineIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('RECTANGLE')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='RECTANGLE'}">
              <rectangleIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('CIRCLE')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='CIRCLE'}">
              <circleIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('TEXT')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='TEXT'}">
              <textIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('MARKER')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='MARKER'}">
              <markerIcon style='width: 25px'/>
            </div>
          </div>
        </div>
        <div class='sl-mt-10px sl-flex-align-items-center sl-flex-column'>
          <SiteMapDrawingSettings
            :drawingMode='drawingMode'
            :activeText='textInput'
            @set-stroke-color='setStrokeColor'
            @set-fill-color='setFillColor'
            @set-stroke-weight='setStrokeWeight'
            @set-stroke-opacity='setStrokeOpacity'
            @set-fill-opacity='setFillOpacity'
            @add-text-to-object='addTextToActiveObject'
          />
          <div class='sl-border-t-1px sl-w-100per sl-mt-10px'>
            <label for='portrait' class='sl-custom-radio sl-w-180px disabled-font sl-mt-20px sl-ml-20px'>
              Portrait
              <input @click="toggleAspect(true)" id='portrait' type='radio' :checked="aspect===true"/>
              <span class='checkmark'></span>
            </label>
            <label for='landscape' class='sl-custom-radio sl-w-180px disabled-font sl-mt-10px sl-ml-20px'>
              Landscape
              <input @click="toggleAspect(false)" id='landscape' type='radio' :checked="aspect===false"/>
              <span class='checkmark'></span>
            </label>
          </div>
          <div class='sl-border-t-1px sl-w-100per sl-mt-10px'>
            <div class='sl-mt-20px'><!-- Placeholder for spacing --></div>
            <div class='sl-flex-align-items-center'>
              <label for='site_map' class='sl-custom-radio sl-w-60px disabled-font sl-ml-20px'>
                Site Map
                <input @click="toggleMapType('Site Map')" id='site_map' class='map-type-selector' name='map_type' type='radio' :checked="checkMapType('Site Map')"/>
                <span class='checkmark'></span>
              </label>
              <span v-if="checkMapType('Site Plan')" class='sl-tooltip sl-mb-10px'>
                <span @click="copyMap('Site Plan', 'Site Map')" class='sl-bold-text pl_copy_link_btn'>Copy</span>
                <span class='sl-tooltiptext'>Copy Site Map into Site Plan</span>
              </span>
            </div>
            <div class='sl-flex-align-items-center'>
              <label for='site_plan' class='sl-custom-radio sl-w-60px disabled-font sl-ml-20px'>
                Site Plan
                <input @click="toggleMapType('Site Plan')" id='site_plan' class='map-type-selector' name='map_type' type='radio' :checked="checkMapType('Site Plan')"/>
                <span class='checkmark'></span>
              </label>
              <span v-if="checkMapType('Site Map')" class='sl-tooltip sl-mb-10px'>
                <span @click="copyMap('Site Map', 'Site Plan')" class='sl-bold-text pl_copy_link_btn'>Copy</span>
                <span class='sl-tooltiptext'>Copy Site Plan into Site Map</span>
              </span>
            </div>
          </div>
        </div>
        <div class='sl-border-t-1px sl-mt-10px sl-pt-10px sl-flex-justify-content-center'>
          <button @click='save({alert: true})'
                  class='sl-simple-outline-btn sl-w-85px sl-mt-5px'>
                  Save
          </button>
        </div>
      </div>
    </div>
    <!-- Map -->
    <div v-if='locationIsSet' id='map' class='map-container-portrait' :class="{'map-container-landscape':!aspect}"></div>
    <div v-else class='map-container-portrait sl-text-align-center' style='background-color: #F5F5F5;'>
      <div v-if='dataReady' class='sl-font-size-14px sl-mt-60px'>The property location has not been set.</div>
    </div>
    <!-- Right Side Pannel -->
    <div class='sl-flex-column sl-flex-align-items-center sl-pl-10px sl-ml-10px'
         style='height: 400px; width: 250px'>
      <div v-if='mapObjects.length' class='sl-w-100per'>
        <ul style='min-height: 244px' id='map-object-container'>
          <li @click='panToLocation'
              class='map-object-layer'
              :class="{'map-object-active':activeIndex===null}">
            <div class='sl-ml-5px sl-flex-align-items-center'>
              <span class='property-dot'></span>
              <label class='sl-ml-10px'>Property</label>
            </div>
          </li>
          <li v-for='(mapObject, index) in mapObjects'
              @click='setMapObjectActive(index)'
              class='map-object-layer'
              :class="{'sl-border-b-1px':isLastRow(index), 'map-object-active':index===activeIndex}">
            <div class='sl-ml-5px sl-flex-align-items-center'>
              <span class='dot' :style="{'background-color': returnMapObjectDotColor(mapObject)}"></span>
              <label class='sl-ml-10px'>{{ returnMapObjectLabel(mapObject) }}</label>
            </div>
            <deleteIcon @click='deleteMapObject(index)' transform='scale(0.7)' class='figma-icon-delete links-center sl-mr-5px'/>
          </li>
        </ul>
      </div>
      <div v-else class='sl-w-100per'>
        <ul>
          <li @click='panToLocation'
              class='map-object-layer sl-border-b-1px'
              :class="{'map-object-active':activeIndex===null}">
            <div class='sl-ml-5px sl-flex-align-items-center'>
              <span class='property-dot'></span>
              <label class='sl-ml-10px'>Property</label>
            </div>
          </li>
        </ul>
        <ol class='sl-pl-20px sl-pt-10px sl-pb-10px' style='height: 190px'>
          <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'>Center map to the property</li>
          <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'> Draw the property boundary</li>
          <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'> Create document</li>
        </ol>
      </div>

      <div class='sl-pt-20px sl-w-90per sl-plr-5px'>
        <!-- Site Map Download Link -->
        <div  v-if='sitemapdocId' class='sl-flex-align-items-center'>
          <a :href=siteMapLink() class='sl-tooltip'>
            <pdfIcon class='sl-mr-10px figma-icon'/>
            <span class='sl-tooltiptext'>Download Site Map</span>
          </a>
          <div>
            <div class='sl-bold-text' style='color: #333E47'>Figure 1</div>
            <div class='disabled-font'>Site Map</div>
          </div>
        </div>
        <div  v-else class='sl-flex-align-items-center'>
          <div class='sl-tooltip'>
            <pdfIconDisabled class='sl-mr-10px'/>
            <span class='sl-tooltiptext'>Site Map Not Created</span>
          </div>
          <div>
            <div class='sl-bold-text disabled-font'>Figure 1</div>
            <div class='disabled-font'>Site Map</div>
          </div>
        </div>
        <!-- Site Plan Download Link -->
        <div v-if='siteplandocId' class='sl-flex-align-items-center sl-mt-10px'>
          <a :href=sitePlanLink() class='sl-tooltip'>
            <pdfIcon class='sl-mr-10px figma-icon'/>
            <span class='sl-tooltiptext'>Download Site Plan</span>
          </a>
          <div>
            <div class='sl-bold-text' style='color: #333E47'>Figure 2</div>
            <div class='disabled-font'>Site Plan</div>
          </div>
        </div>
        <div v-else class='sl-flex-align-items-center sl-mt-10px'>
          <div class='sl-tooltip'>
            <pdfIconDisabled class='sl-mr-10px'/>
            <span class='sl-tooltiptext'>Site Plan Not Created</span>
          </div>
          <div>
            <div class='sl-bold-text disabled-font'>Figure 2</div>
            <div class='disabled-font'>Site Plan</div>
          </div>
        </div>
      </div>
      <div class='sl-border-t-1px sl-mt-10px sl-pt-20px sl-w-90per sl-flex-justify-content-center sl-plr-5px'>
        <button @click='createDocument()' class='sl-simple-outline-btn sl-w-150px sl-mr-5px sl-h-28px'>Create Document</button>
      </div>
    </div>
  </div>
  <div id='newCanvas'></div>
</template>
<script>
// @ts-nocheck
import axios from 'axios';
import { toRaw } from 'vue';
import deleteIcon from '../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import pointerIcon from '../../../assets/images/icons/map_pointer.svg';
import markerIcon from '../../../assets/images/icons/map_marker.svg';
import rectangleIcon from '../../../assets/images/icons/map_square.svg';
import infoIcon from '../../../assets/images/icons/map_info_marker.svg';
import circleIcon from '../../../assets/images/icons/map_circle.svg';
import textIcon from '../../../assets/images/icons/map_text_icon.svg';
import lineIcon from '../../../assets/images/icons/map_line.svg';
import SiteMapDrawingSettings from './site_map/SiteMapDrawingSettings.vue';
import SpinningModal from '../components/SpinningModal.vue';
import pdfIcon from "../../../assets/images/icons/Icon-PDF-Doc_Charcoal-Dark-100_24.svg"
import pdfIconDisabled from "../../../assets/images/icons/Icon-PDF-Doc_Charcoal-Medium-100_24.svg"
import { set } from 'lodash';

export default {
  components: {
    SiteMapDrawingSettings,
    SpinningModal,
    deleteIcon,
    pointerIcon,
    markerIcon,
    rectangleIcon,
    infoIcon,
    circleIcon,
    textIcon,
    lineIcon,
    pdfIcon,
    pdfIconDisabled,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      projectId: urlParams.get('id'),
      projectScopeId: urlParams.get('project_scope_id'),
      project: null,
      colors: ['#005199', '#569900', '#9F9EA4', '#1F7A8C', '#FF3333', '#F04C25', '#000000'],
      googleMap: null,
      siteMap: null,
      location: {},
      locationIsSet: false,
      sitemapdocId: null,
      siteplandocId: null,
      locationMarker: null,
      // Map objects reprent the objects saved in the database
      mapObjects: [],
      // Map drawings reprent the objects passed into the map interface
      mapDrawings: [],
      activeIndex: null,
      textInput: null,
      drawingManager: null,
      drawingMode: 'POINTER',
      strokeColor: '#005199',
      fillColor: '#005199',
      strokeWeight: 10,
      strokeOpacity: 1.0,
      fillOpacity: 0.1,
      siteMapDownloadLink: null,
      overlayLimit: 15,
      showLoading: false,
      alertMessage: null,
      aspect: true,
      documentType: 'Site Map',
      dataReady: false,
      mapSaved: true,
    };
  },

  mounted() {
    this.fetchMap();
    this.setPageExitPrompt();
  },

  methods: {
    async fetchMap() {
      this.showLoading = true;
      await axios.get('/site_maps/project_data', {
        params: {
          project_id: this.projectId,
          project_scope_id: this.projectScopeId,
          document_type: this.documentType,
        },
      })
        .then((response) => {
          this.project = response.data.project;
          this.siteMap = response.data.site_map;
          this.mapObjects = response.data.map_objects;
          this.location = response.data.location;
          this.sitemapdocId = response.data.sitemapdocId;
          this.siteplandocId = response.data.siteplandocId;
          this.aspect = response.data.orientation;
          if (this.siteMap.lat && this.siteMap.lng) {
            this.locationIsSet = true;
          }
          this.initialize();
          this.dataReady = true;
          this.mapSaved = true;
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, map not loaded';
        })
        .finally(() => {
          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        });
    },

    // Create Map
    async initialize() {
      const { Map } = await google.maps.importLibrary('maps');
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
      const map = new Map(document.getElementById('map'), {
        center: { lat: parseFloat(this.siteMap.lat), lng: parseFloat(this.siteMap.lng) },
        zoom: parseInt(this.siteMap.zoom),
        mapTypeId: this.siteMap.map_type,
        tilt: this.siteMap.tilt,
        mapId: '7427cd8c9a3fcba3',
      });
      const location = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(this.location.lat), lng: parseFloat(this.location.lng) },
        map,
      });
      this.locationMarker = location;
      this.createListeners(map);
      this.loadDrawingManager(map);
      this.loadMapObjects(map);
      this.googleMap = map;
    },

    // Event listeners for when the map is interacted with, updates the site map data
    createListeners(map) {
      map.addListener('center_changed', () => {
        this.siteMap.lat = map.center.lat();
        this.siteMap.lng = map.center.lng();
        this.mapSaved = false;
      });
      map.addListener('zoom_changed', () => {
        this.siteMap.zoom = map.zoom;
        this.mapSaved = false;
      });
      map.addListener('maptypeid_changed', () => {
        this.siteMap.map_type = map.mapTypeId;
        this.mapSaved = false;
      });
      map.addListener('tilt_changed', () => {
        this.siteMap.tilt = map.tilt;
        this.mapSaved = false;
      });
    },

    // Move the map to the property location
    panToLocation() {
      this.activeIndex = null;
      this.setAllEditable(false);
      this.googleMap.panTo(this.locationMarker.position);
    },

    // Load map objects that are saved in the database
    loadMapObjects(map) {
      this.mapDrawings = [];
      this.mapObjects.forEach((object, index) => {
        switch (object.type) {
          case 'Line':
            this.loadLine(object, map, index);
            break;
          case 'Rectangle':
            this.loadRectangle(object, map, index);
            break;
          case 'Circle':
            this.loadCircle(object, map, index);
            break;
          case 'Text':
            this.loadText(object, map, index);
            break;
          case 'Marker':
            this.loadMarker(object, map, index);
            break;
        }
      });
    },

    // Load line map objects
    loadLine(data, map, index) {
      const points = [];
      data.points.forEach((datapoint) => {
        const point = new google.maps.LatLng(datapoint.lat, datapoint.lng);
        points.push(point);
      });
      const line = new google.maps.Polyline({
        index: index,
        type: data.type,
        path: points,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        editable: false,
        map,
      });
      line.addListener('click', () => {
        this.activeIndex = index;
        line.setEditable(true);
        this.setAllEditable(false);
      });
      this.mapDrawings.push(line);
    },

    // Load rectangle map objects
    loadRectangle(data, map, index) {
      const rectangle = new google.maps.Rectangle({
        index: index,
        type: data.type,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        fillColor: data.fill_color,
        fillOpacity: data.fill_opacity,
        editable: false,
        map,
        bounds: {
          north: parseFloat(data.north),
          south: parseFloat(data.south),
          east: parseFloat(data.east),
          west: parseFloat(data.west),
        },
      });
      rectangle.addListener('click', () => {
        this.activeIndex = index;
        this.setAllEditable(false);
        rectangle.setEditable(true);
      });
      this.mapDrawings.push(rectangle);
    },

    // Load circle map objects
    loadCircle(data, map, index) {
      const circle = new google.maps.Circle({
        index: index,
        type: data.type,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        fillColor: data.fill_color,
        fillOpacity: data.fill_opacity,
        editable: false,
        map,
        center: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        radius: parseFloat(data.radius),
      });
      circle.addListener('click', () => {
        this.setAllEditable(false);
        circle.setEditable(true);
        this.activeIndex = index;
      });
      this.mapDrawings.push(circle);
    },

    // Load text map objects
    loadText(data, map, index) {
      const mapTextBox = document.createElement('div');
      mapTextBox.className = 'site-map-text-box';
      mapTextBox.textContent = data.content;

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        content: mapTextBox,
        gmpDraggable: true,
        title: 'Text',
        map,
      });
      marker.addListener('click', () => {
        this.activeIndex = index;
        this.setAllEditable(false);
      });
      marker.addListener('drag', () => {
        this.mapSaved = false;
        this.activeIndex = index;
        this.setAllEditable(false);
      });
      this.mapDrawings.push(marker);
    },

    // Load marker map objects
    loadMarker(data, map, index) {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        gmpDraggable: true,
        title: 'Marker',
        map,
      });
      marker.addListener('click', () => {
        this.activeIndex = index;
        this.setAllEditable(false);
      });
      marker.addListener('drag', () => {
        this.mapSaved = false;
        this.activeIndex = index;
        this.setAllEditable(false);
      });
      this.mapDrawings.push(marker);
    },

    // Create the drawing manager
    loadDrawingManager(map) {
      const drawing_options = {
        strokeColor: this.strokeColor,
        fillColor: this.fillColor,
        strokeWeight: this.strokeWeight,
        strokeOpacity: this.strokeOpacity,
        fillOpacity: this.fillOpacity,
        clickable: true,
        editable: true,
        zIndex: 1,
      };
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {},
        polygonOptions: drawing_options,
        polylineOptions: drawing_options,
        rectangleOptions: drawing_options,
        circleOptions: drawing_options,
      });
      drawingManager.setMap(map);
      // Marker is added to the map
      google.maps.event.addListener(drawingManager, 'markercomplete', (item) => {
        this.mapSaved = false;
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        // The drawing manager creates a legacy marker by default.
        // Create an advanced marker instead and remove the legacy marker from the map.
        item.setMap(null);
        const index = this.mapObjects.length;
        let advancedMarker = new google.maps.marker.AdvancedMarkerElement({
          position: item.getPosition(),
          gmpDraggable: true,
          map,
        });

        // Text box is added to the map
        if (this.drawingMode === 'TEXT') {
          this.textInput = document.getElementById('text_input').value;
          // Custom div element for text box
          const mapTextBox = document.createElement('div');
          mapTextBox.className = 'site-map-text-box';
          mapTextBox.textContent = this.textInput;
          advancedMarker.content = mapTextBox;
          advancedMarker.title = 'Text';
          this.mapObjects.push({
            type: 'Text',
            index,
            lat: item.getPosition().lat(),
            lng: item.getPosition().lng(),
            content: this.textInput,
          });
        // Marker is added to the map
        } else {
          advancedMarker.title = 'Marker';
          this.mapObjects.push({
            type: 'Marker',
            index,
            lat: item.getPosition().lat(),
            lng: item.getPosition().lng(),
          });
        }
        this.activeIndex = index;
        this.mapDrawings.push(advancedMarker);
        this.disablePreviousEditable();

        item.addListener('click', () => {
          this.activeIndex = index;
          this.setAllEditable(false);
        });
        item.addListener('drag', () => {
          this.mapSaved = false;
          this.activeIndex = index;
          this.setAllEditable(false);
        });
      });
      // Rectangle is added to the map
      google.maps.event.addListener(drawingManager, 'rectanglecomplete', (item) => {
        this.mapSaved = false;
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        const index = this.mapObjects.length;
        this.mapObjects.push({
          type: 'Rectangle',
          index,
          north: item.getBounds().getNorthEast().lat(),
          south: item.getBounds().getSouthWest().lat(),
          east: item.getBounds().getNorthEast().lng(),
          west: item.getBounds().getSouthWest().lng(),
          stroke_color: item.strokeColor,
          fill_color: item.fillColor,
          stroke_opacity: item.strokeOpacity,
          fill_opacity: item.fillOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
        });
        item.addListener('click', () => {
          this.activeIndex = index;
          this.setAllEditable(false);
          item.setEditable(true);
        });
        item.set('type', 'Rectangle');
        this.activeIndex = index;
        this.mapDrawings.push(item);
        this.disablePreviousEditable();
      });
      // Circle is added to the map
      google.maps.event.addListener(drawingManager, 'circlecomplete', (item) => {
        this.mapSaved = false;
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        const index = this.mapObjects.length;
        this.mapObjects.push({
          type: 'Circle',
          index,
          lat: item.getCenter().lat(),
          lng: item.getCenter().lng(),
          radius: item.getRadius(),
          stroke_color: item.strokeColor,
          fill_color: item.fillColor,
          stroke_opacity: item.strokeOpacity,
          fill_opacity: item.fillOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
        });
        item.addListener('click', () => {
          this.activeIndex = index;
          this.setAllEditable(false);
          item.setEditable(true);
        });
        item.set('type', 'Circle');
        this.activeIndex = index;
        this.mapDrawings.push(item);
        this.disablePreviousEditable();
      });
      // Line is added to the map
      google.maps.event.addListener(drawingManager, 'polylinecomplete', (item) => {
        this.mapSaved = false;
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        const index = this.mapObjects.length;
        const points = (item.getPath().getArray().map((point) => ({ lat: point.lat(), lng: point.lng() })));
        this.mapObjects.push({
          type: 'Line',
          index,
          stroke_color: item.strokeColor,
          stroke_opacity: item.strokeOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
          points,
        });
        item.addListener('click', () => {
          this.activeIndex = index;
          this.setAllEditable(false);
          item.setEditable(true);
        });
        item.set('type', 'Line');
        this.activeIndex = index;
        this.mapDrawings.push(item);
        this.disablePreviousEditable();
      });

      this.drawingManager = drawingManager;
    },

    // Set the drawing mode of the left side panel and the drawing manager
    setDrawingMode(mode) {
      this.drawingMode = mode;
      this.textInput = null;
      if (mode === 'TEXT') {
        this.drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType.MARKER,
        });
      } else {
        this.drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType[mode],
        });
      }
    },

    // Set the stroke color of the drawing manager and the map objects
    setStrokeColor(color) {
      this.strokeColor = color;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.strokeColor) {
        activeDrawing.set('strokeColor', color);
        activeObject.stroke_color = color;
      }
    },

    // Set the fill color of the drawing manager and the map objects
    setFillColor(color) {
      this.fillColor = color;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.fillColor) {
        activeDrawing.set('fillColor', color);
        activeObject.fill_color = color;
      }
    },

    // Set the stroke weight of the drawing manager and the map objects
    setStrokeWeight(strokeWeight) {
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.strokeWeight) {
        activeDrawing.set('strokeWeight', strokeWeight);
        activeObject.stroke_weight = strokeWeight;
      }
    },

    // Set the stroke opacity of the drawing manager and the map objects
    setStrokeOpacity(opacity) {
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.strokeOpacity) {
        activeDrawing.set('strokeOpacity', opacity);
        activeObject.stroke_opacity = opacity;
      }
    },

    // Set the fill opacity of the drawing manager and the map objects
    setFillOpacity(opacity) {
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.fillOpacity) {
        activeDrawing.set('fillOpacity', opacity);
        activeObject.fill_opacity = opacity;
      }
    },

    // Update the drawing manager with the current drawing settings, restrct settings by drawing mode
    updateDrawingManager() {
      const drawing_options = {
        strokeColor: this.strokeColor,
        fillColor: this.fillColor,
        strokeWeight: this.strokeWeight,
        strokeOpacity: this.strokeOpacity,
        fillOpacity: this.fillOpacity,
        clickable: true,
        editable: true,
        zIndex: 1,
      };
      this.drawingManager.setOptions({
        polygonOptions: drawing_options,
        polylineOptions: drawing_options,
        rectangleOptions: drawing_options,
        circleOptions: drawing_options,
      });
    },

    // Add or remove the editable property from all map objects
    setAllEditable(editable) {
      this.mapDrawings.forEach((drawing) => {
        if (drawing.setEditable) {
          drawing.setEditable(editable);
        }
      });
    },

    // Set the most recent map object to editable and all other map objects to not editable
    disablePreviousEditable() {
      const index = this.mapDrawings.length - 1;
      this.setAllEditable(false);
      if (this.mapDrawings[index]?.setEditable) {
        this.mapDrawings[index].setEditable(true);
      }
    },

    // Show the text box when the drawing manager is in text mode or a text object is active
    showTextarea() {
      const activeObject = this.mapObjects[this.activeIndex];
      return this.drawingMode === 'TEXT' || (activeObject && activeObject.type === 'Text' && activeObject.content);
    },

    // Add text to text box when it is in active mode
    addTextToActiveObject(textInput) {
      this.mapSaved = false;
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeObject && activeObject.type === 'Text') {
        const activeDrawing = this.mapDrawings[this.activeIndex];
        if (activeDrawing) {
          activeDrawing.content.textContent = textInput;
          this.mapObjects[this.activeIndex].content = textInput;
        }
      }
    },


    // Save map and all map objects
    async save(params) {
      this.showLoading = true;
      if (this.updateMapObjects()) {
        await axios.put(`/site_maps/${this.siteMap.id}`, null, {
          params: {
            site_map: this.siteMap,
            map_objects: this.mapObjects,
          },
        })
          .then(() => {
            this.mapSaved = true;
            this.setAllEditable(false);
            this.textInput = null;
            setTimeout(() => {
              this.showLoading = false;
            }, 500);
            if (params.alert) {
              this.alertMessage = 'Map Saved';
              setTimeout(() => {
                this.alertMessage = '';
              }, 3000);
            }
          })
          .catch(() => {
            this.alertMessage = 'Something went wrong, map not saved';
            this.showLoading = false;
          });
      } else {
        this.showLoading = false;
      }
    },

    // Before sending data to the server, update all map objects with the current map drawing settings
    updateMapObjects() {
      let valid = true;
      this.mapDrawings.forEach((drawing, index) => {
        if (drawing.type === 'Line') {
          this.mapObjects[index].points = drawing.getPath().getArray().map((point) => (
            { lat: point.lat(), lng: point.lng() }
          ));
          if (this.mapObjects[index].points.length > 30) {
            this.alertMessage = 'Line has too many points, please draw the line with less than 30 points';
            valid = false;
          }
        } else if (drawing.type === 'Rectangle') {
          this.mapObjects[index].north = drawing.getBounds().getNorthEast().lat();
          this.mapObjects[index].south = drawing.getBounds().getSouthWest().lat();
          this.mapObjects[index].east = drawing.getBounds().getNorthEast().lng();
          this.mapObjects[index].west = drawing.getBounds().getSouthWest().lng();
        } else if (drawing.type === 'Circle') {
          this.mapObjects[index].lat = drawing.getCenter().lat();
          this.mapObjects[index].lng = drawing.getCenter().lng();
          this.mapObjects[index].radius = drawing.getRadius();
        } else if (drawing.title === 'Marker') {
          this.mapObjects[index].lat = drawing.position.lat;
          this.mapObjects[index].lng = drawing.position.lng;
        } else if (drawing.title === 'Text') {
          this.mapObjects[index].lat = drawing.position.lat;
          this.mapObjects[index].lng = drawing.position.lng;
          this.mapObjects[index].content = drawing.content.textContent;
        }
      });
      return valid;
    },

    // Delete Map Objects
    async deleteMapObject(index) {
      toRaw(this.mapDrawings[index]).setMap(null);
      this.mapDrawings.splice(index, 1);
      this.mapObjects.splice(index, 1);
      this.mapSaved = false;
    },

    // Sets a single map object to active and the drawing mode to that object's type
    setMapObjectActive(index) {
      const activeObject = this.mapObjects[index];
      if (activeObject?.type === 'Text') {
        this.setDrawingMode('TEXT');
      } else if (activeObject?.type === 'Line') {
        this.setDrawingMode('POLYLINE');
      } else if (activeObject?.type === 'Rectangle') {
        this.setDrawingMode('RECTANGLE');
      } else if (activeObject?.type === 'Circle') {
        this.setDrawingMode('CIRCLE');
      } else if (activeObject?.type === 'Marker') {
        this.setDrawingMode('MARKER');
      } else {
        this.setDrawingMode('POINTER');
      }
      this.activeIndex = index;

      this.setAllEditable(false);
      // Most of the logic below handles the panning of the map to the active object
      if (activeObject && activeObject.type) {
        if (this.mapDrawings[index].setEditable) {
          this.mapDrawings[index].setEditable(true);
        }
        if (['Circle', 'Marker'].includes(activeObject.type)) {
          const position = { lat: parseFloat(activeObject.lat), lng: parseFloat(activeObject.lng) };
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Text') {
          const position = { lat: parseFloat(activeObject.lat), lng: parseFloat(activeObject.lng) };
          this.textInput = activeObject.content;
          // Delay to ensure the textarea is on the page
          setTimeout(() => {
            document.getElementById('text_input').value = activeObject.content;
          }, 50);
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Rectangle') {
          const difference1 = (parseFloat(activeObject.south) - parseFloat(activeObject.north)) / 2;
          const difference2 = (parseFloat(activeObject.east) - parseFloat(activeObject.west)) / 2;
          const position = {
            lat: parseFloat(activeObject.south) + difference1,
            lng: parseFloat(activeObject.west) + difference2,
          };
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Line') {
          const lat = parseFloat(activeObject.points[0].lat);
          const lng = parseFloat(activeObject.points[0].lng);
          const position = { lat, lng };
          this.googleMap.panTo(position);
        }
      }
    },

    // Returns true if the index is the last row in the map objects array
    isLastRow(index) {
      return index === this.mapObjects.length - 1;
    },

    // PDF document creation
    async createDocument() {
      this.showLoading = true;
      this.setAllEditable(false);
      window.scrollTo(0, 0);
      this.hideMapWaterMark();
      await this.hideMapControls()
        .then(() => {
          this.captureMap();
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, document not created';
        });
    },

    // Hide the map controls and location marker before creating the PDF
    hideMapControls() {
      return new Promise((resolve) => {
        this.locationMarker.position = null;
        this.googleMap.setOptions({ disableDefaultUI: true });
        setTimeout(() => {
          resolve('resolved');
        }, 500);
      });
    },

    // Hide the Google watermark from the map before creating the PDF
    hideMapWaterMark() {
      const elements1 = document.querySelectorAll('.map-container-portrait a');
      const elements2 = document.querySelectorAll('.map-container-landscape a');
      elements1.forEach((element) => { element.style.display = 'none'; });
      elements2.forEach((element) => { element.style.display = 'none'; });
    },

    // Show the google watermark after creating the PDF
    showMapWaterMark() {
      const elements1 = document.querySelectorAll('.map-container-portrait a');
      const elements2 = document.querySelectorAll('.map-container-landscape a');
      elements1.forEach((element) => { element.style.display = 'block'; });
      elements2.forEach((element) => { element.style.display = 'block'; });
    },

    // Take a screenshot of the map and send it to the server
    captureMap() {
      const map = document.getElementById('map');
      let img;
      html2canvas(map, { useCORS: true, scale: 4 })
        .then((canvas) => {
          const new_canvas = document.querySelector('#newCanvas').appendChild(canvas);
          img = new_canvas.toDataURL('image/png');
          document.querySelector('#newCanvas').removeChild(canvas);
        })
        .then(() => {
          this.sendDocument(img);
        });
    },

    // Ajax call to the server to create the PDF, returns the document id
    async sendDocument(imgData) {
      await axios.post('/projects/download_pdf_vue', {
        project_id: this.projectId,
        project_scope_id: this.projectScopeId,
        img_data: imgData,
        site_plan_or_map: this.documentType,
        landscape_or_portrait: this.aspect ? 'Portrait' : 'Landscape',
        seismic: false,
        rmp: false,
        zoning: false,
      })
        .then((response) => {
          if (this.documentType === 'Site Map') {
            this.sitemapdocId = response.data.projectdoc_id;
          } else {
            this.siteplandocId = response.data.projectdoc_id;
          }
          this.locationMarker.position = { lat: parseFloat(this.location.lat), lng: parseFloat(this.location.lng) };
          this.googleMap.setOptions({ disableDefaultUI: false });
          this.showMapWaterMark();
          this.alertDocumentCreated();
          this.save({alert: false});
          this.mapSaved = true;
          this.showLoading = false;
          document.body.style.overflowY = 'scroll';
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, document not created';
          this.showLoading = false;
        });
    },

    // Copy the Site Map data to the Site Plan or vice versa
    async copyMap(mapTypeToCopyTo, mapTypeToCopyFrom) {
      const message = `This will overwrite the current ${mapTypeToCopyTo} with the current ${mapTypeToCopyFrom}. Are you sure you want to continue?`;
      if (!window.confirm(message)) { return; }
      this.showLoading = true;
      await axios.post('/site_maps/copy_map', {
        project_scope_id: this.projectScopeId,
        map_type_to_copy_to: mapTypeToCopyTo,
        map_type_to_copy_from: mapTypeToCopyFrom,
      })
        .then((response) => {
          this.siteMap = response.data.map;
          this.mapObjects = response.data.map_objects;
          this.initialize();
          this.mapSaved = true;
          this.aspect = this.siteMap.orientation;
          this.alertMessage = 'Map Copied';
          this.showLoading = false;
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, map not copied';
          this.showLoading = false;
        });
    },

    // Return the link to the site map PDF
    siteMapLink() {
      if (this.sitemapdocId && this.project) {
        const projectNumberString = this.project.project_number_string;
        return `/projectdocs/${this.sitemapdocId}?custom_file_name=${projectNumberString}Figure+1+Site+Location+Map.pdf`;
      }
      return '';
    },

    // Return the link to the site plan PDF
    sitePlanLink() {
      if (this.siteplandocId && this.project) {
        const projectNumberString = this.project.project_number_string;
        return `/projectdocs/${this.siteplandocId}?custom_file_name=${projectNumberString}Figure+1+Site+Location+Plan.pdf`;
      }
      return '';
    },

    // Show the alert message that the document has been created
    alertDocumentCreated() {
      this.alertMessage = `${this.documentType} Created`;
    },

    // Toggle between Site Map and Site Plan
    toggleMapType(mapType) {
      const confirmMessage = 'Any unsaved changes will be lost. Are you sure you want to continue?';
      if (this.mapSaved || window.confirm(confirmMessage)) {
        this.documentType = mapType;
        this.mapObjects = [];
        this.mapDrawings = [];
        this.fetchMap();
      // reset the map type radio buttons
      } else if (mapType === 'Site Map') {
        document.querySelector('.map-type-selector').checked = false;
        document.getElementById('site_plan').checked = true;
      } else if (mapType === 'Site Plan') {
        document.querySelector('.map-type-selector').checked = false;
        document.getElementById('site_map').checked = true;
      }
    },

    // Toggle between portrait and landscape
    toggleAspect(aspect) {
      this.aspect = aspect;
      this.siteMap.orientation = aspect;
      this.mapSaved = false;
    },

    // Used for single choice radio buttons
    checkMapType(mapType) {
      if (this.documentType === mapType) {
        return true;
      }
      return false;
    },

    // Return the color of the map object dot in the right side panel
    returnMapObjectDotColor(mapObject) {
      // Text objects do not have a stroke color, assign it blue
      if (mapObject.type === 'Text') {
        return '#3374AD';
      }
      // Every other map object, return the stroke color
      return mapObject.stroke_color;
    },

    // Return the label for the map object in the right side panel; for text objects, return their content
    returnMapObjectLabel(mapObject) {
      // For text objects with more than 20 characters
      if (mapObject.type === 'Text' && mapObject?.content?.length > 20) {
        return mapObject.content.substring(0, 20) + '...';
      // For text objects with less than 20 characters
      } else if (mapObject.type === 'Text' && mapObject.content) {
        return mapObject.content;
      }
      // For all other map objects, return the type
      return mapObject.type;
    },

    // Show an alert message if the user tries to leave the page with unsaved changes
    setPageExitPrompt() {
      window.onbeforeunload = () => {
        if (!this.mapSaved) {
          return 'You have unsaved changes. Are you sure you want to leave this page?';
        }
        return null;
      };
    },
  },
};
</script>
<style scoped>
  .site-map-clr-btn {
    border: 0;
    width: 21px;
    height: 21px;
    cursor: pointer;
    margin-right: 10px;
  }
  .active-tool-btn {
    border: 1px solid #CCDCEB;
    background-color: #CCDCEB !important;
  }
  .map_tool_btn:hover {
    background-color: #FBFBFB;
  }
  .map_tool_btn {
    margin: 3px;
    border: 1px solid rgb(222, 218, 218);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  .map-object-layer {
    color: #005199;
    font-weight: bold;
    border-top: 1px solid var(--medium-charcoal);
    font-size: 12px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .map-object-layer:hover {
    color: #005199;
    background-color: #FBFBFB;
  }
  .map-object-active {
    color: #005199;
    background-color: #E5EEF5 !important;
  }
  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: #F04C25;
  }
  .property-dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #005199;
  }
  .map-container-portrait {
    width: 600px;
    height: 790px;
  }
  .map-container-landscape {
    min-width: 760px !important;
    width: 760px !important;
    height: 405px;
  }
  .overflow {
    overflow-x: scroll;
  }
</style>
