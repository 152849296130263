<template>
  <td class='sl-w-100px sl-flex-column sl-text-wrap'>
    <div class='sl-h-12px'></div>
    <div class='sl-tooltip'>
      <span @click='sortValues.sort(data?.column)'
        :class="{ 'sl-blue-link': sortValues.column === data?.column }"
        class='links-center sl-tooltip'>
        <!-- If the column is sorted, display the sorted header -->
        <span v-if='sortValues.column === data?.column' v-html='data?.sorted_header'></span>
        <!-- Otherwise, display the default header -->
        <span v-else v-html='data?.header'></span>
      </span>
      <!-- Tooltip text -->
      <span class='sl-tooltiptext sl-measures-tooltiptext' v-html='data?.tooltip_text'></span>
    </div>
    <div class='sl-h-12px'>
      <UpArrow v-if="sortValues.column === data?.column && sortValues.direction === 'asc'"/>
      <DownArrow v-else-if="sortValues.column === data?.column && sortValues.direction === 'desc'"/>
    </div>
  </td>
</template>
<script>
import UpArrow from '/javascript/components/icons/UpArrowSmall.vue';
import DownArrow from '/javascript/components/icons/DownArrowSmall.vue';

export default {
  components: {
    UpArrow,
    DownArrow,
  },
  props: {
    data: {
      type: Object,
    },
    sortValues: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
  .sl-measures-tooltiptext {
    font-size: 12px !important;
    color: var(--dark-charcoal) !important;
    font-weight: normal !important;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    white-space: nowrap;
    margin-left: v-bind('data?.tooltip_margin_left');
    text-align: left !important;
  }

  .sl-measures-tooltiptext::before {
    left: v-bind('data?.tooltip_arrow_left');
  }

  .sl-measures-tooltiptext::after {
    left: v-bind('data?.tooltip_arrow_left');
  }
</style>
