<template>
  <SpinningModal v-if='showSpinningModal' :modalLoadingText="'Fetching Policy Data...'"/>
  <div v-if='dataReady'>
    <h1 class='nd_photos_h1_style sl-mtb-10px'>{{ policyName }}</h1>
    <PropertyInformation :propertyData='propertyData'/>
    <PolicyTable :policy='policy.summary_data' title='Policy Summary'/>
    <PolicyTable :policy='policy.details_data' title='Policy Details'/>
  </div>
</template>
<script>
import axios from 'axios';
import PropertyInformation from './PropertyInformation.vue';
import PolicyTable from './PolicyTable.vue';
import SpinningModal from '../../../components/SpinningModal.vue';

export default {
  name: 'EnergyCompliancePolicy',

  components: {
    PropertyInformation,
    PolicyTable,
    SpinningModal,
  },

  data() {
    return {
      projectScopeId: null,
      propertyData: {},
      policyId: null,
      policy: null,
      policyName: null,
      dataReady: false,
      showSpinningModal: false,
    };
  },

  mounted() {
    this.setPolicyId();
    this.setProjectScopeId();
    this.fetchPolicy();
  },

  methods: {
    setPolicyId() {
      const urlParams = window.location.href.split('/').pop();
      const [policyId] = urlParams.split('?');
      this.policyId = policyId;
    },

    setProjectScopeId() {
      const urlParams = new URLSearchParams(window.location.search);
      this.projectScopeId = urlParams.get('project_scope_id');
    },

    fetchPolicy() {
      this.showSpinningModal = true;
      axios.get('/imt_project_policies/return_policy_json', {
        params: {
          project_scope_id: this.projectScopeId,
          policy_id: this.policyId,
        },
      }).then((response) => {
        this.propertyData = response.data.property_data;
        this.policy = response.data.policy;
        this.policyName = this.policy.summary_data[0].data;
      }).catch(() => {
        alert('Error fetching policy data');
      }).finally(() => {
        setTimeout(() => {
          this.dataReady = true;
          this.showSpinningModal = false;
        }, 500);
      });
    },
  },
};
</script>
