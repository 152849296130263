<template>
  <SpinningModal v-if="showSpinningModal"
    modalLoadingText='Fetching Property Data...'
  />
  <div :class="{ 'content-hidden': !dataReady }">
    <!-- Header for the project specific page -->
    <div v-if='projectId' class='sl-flex-justify-content-space-btwn'>
      <h1 class='nd_photos_h1_style sl-mb-20px'>Utility Benchmarking</h1>
    </div>
    <!-- Header for the energy star portfolio manager page -->
    <EspmPropertyHeader v-else
      :property='property'
      @show-modal='showModal = true'
    />
    <div v-if='projectId && !projectHasEspmId' class='sl-font-size-14px sl-mb-20px'>
      This project does not have an Energy Star Property ID associated with it.
    </div>
    <div v-else-if='projectId && !propertyFound' class='sl-font-size-14px sl-mb-20px'>
      Unable to find a property with the Energy Star Property ID entered. Please check that the correct ID was entered.
    </div>
    <div v-else>
      <PropertyInformation
        :property='property'
        @property-data-ready='propertyDataReady = true'
      />
      <PropertyMetrics
        :propertyId='propertyId'
        :projectId='projectId'
        @metrics-data-ready='metricsDataReady = true'
      />
      <Meters
        :propertyId='propertyId'
        :projectId='projectId'
        :projectScopeId='projectScopeId'
        @meter-data-ready='meterDataReady = true'
      />
      <Tenants
        :propertyId='propertyId'
        :projectId='projectId'
        @tenant-data-ready='tenantDataReady = true'
      />
      <PropertyDataTransferModal
        v-if='showModal'
        :property='property'
        @close-modal='closeModal'
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import PropertyInformation from './EspmPropertyInformation.vue';
import PropertyMetrics from './EspmPropertyMetrics.vue';
import Meters from './EspmMeters.vue';
import Tenants from './EspmTenants.vue';
import EspmPropertyHeader from './EspmPropertyHeader.vue';
import PropertyDataTransferModal from './EspmPropertyDataTransferModal.vue';
import SpinningModal from '../../../components/SpinningModal.vue';

export default {
  components: {
    PropertyInformation,
    PropertyMetrics,
    Meters,
    Tenants,
    EspmPropertyHeader,
    PropertyDataTransferModal,
    SpinningModal,
  },
  created() {
    this.setPropertyId();
    this.fetchProperty();
    this.checkDataReady();
    this.modalBtnListener();
  },
  data() {
    return {
      projectId: null,
      projectScopeId: null,
      propertyId: null,
      property: null,
      fetchingProperty: false,
      showModal: false,
      propertyDataReady: false,
      metricsDataReady: false,
      meterDataReady: false,
      tenantDataReady: false,
      projectHasEspmId: true,
      propertyFound: true,
      showSpinningModal: false,
      dataReady: false,
      alertMessage: null,
    };
  },
  methods: {
    setPropertyId() {
      if (window.location.pathname.includes('project_specific')) {
        const urlParams = new URLSearchParams(window.location.search);
        this.projectId = urlParams.get('project_id');
        this.projectScopeId = urlParams.get('project_scope_id');
        this.propertyId = urlParams.get('property_id');
      } else {
        this.propertyId = window.location.pathname.split('/').pop();
      }
    },

    fetchProperty() {
      this.fetchingProperty = true;
      axios.get('/espm_properties/return_property_json', {
        params: {
          project_id: this.projectId,
          project_scope_id: this.projectScopeId,
          property_id: this.propertyId,
        },
      })
        .then((response) => {
          this.property = response.data.property;
          this.projectHasEspmId = response.data.project_has_espm_id;
          this.propertyFound = response.data.property_found;
        })
        .catch(() => {
          this.alertMessage = 'Error fetching property data';
        })
        .finally(() => {
          this.propertyDataReady = true;
          setTimeout(() => {
            this.fetchingProperty = false;
          }, 500);
        });
    },

    modalBtnListener() {
      const modalBtn = document.getElementById('sl-espm-data-transfer-icon');
      if (modalBtn) {
        modalBtn.addEventListener('click', () => {
          this.showModal = true;
        });
      }
    },

    closeModal() {
      this.showModal = false;
    },

    // Check if the data is ready for all components
    checkDataReady() {
      this.showSpinningModal = true;
      const startTime = Date.now();
      // Check if all data is ready every .3s
      const interval = setInterval(() => {
        // Clear the interval if there is no property id or 10s has passed
        if ((this.propertyDataReady && !this.propertyId) || (Date.now() - startTime > 10000)) {
          clearInterval(interval);
          this.dataReady = true;
          this.showSpinningModal = false;
          return;
        }
        // If all data is ready, clear the interval and set dataReady to true
        if (this.propertyDataReady && this.metricsDataReady && this.meterDataReady && this.tenantDataReady) {
          this.dataReady = true;
          this.showSpinningModal = false;
          clearInterval(interval);
        }
      }, 300);
    },
  },
};
</script>
<style scoped>
.content-hidden {
  display: none;
}
</style>
