<template>
  <CollapsibleSection :barText="'Property Metrics'"
                      :toggleHeaderColor=false
                      :bottomBorder=false
                      class='sl-mb-20px'>
    <form @submit.prevent='fetchMetrics' v-if='propertyMetricsAvailable'>
      <div class='sl-flex-justify-content-space-btwn'>
        <div class='sl-flex-align-items-center sl-w-33per'>
          <div v-if='fetchingMetrics' class='sl-spinning-wheel-20px sl-mr-15px'></div>
        </div>
        <div class='sl-w-33per sl-text-align-center sl-pt-20px sl-bold-text sl-partner-blue-text'>
          {{ alertMessage }}
        </div>
        <div class='sl-flex-justify-content-end sl-mtb-10px sl-w-33per'>
          <div class='sl-border-2px-medium-charcoal sl-text-align-center sl-mr-20px sl-w-150px'>
          <input id='espm-property-metrics-date-input'
                  type='month'
                  :min='minDate'
                  :max='maxDate'
                  v-model='searchDate'>
          </div>
          <button class='sl-simple-outline-btn' type='submit'>Search</button>
        </div>
      </div>
      <table v-if='metrics' class='sl-simple-table-1 espm-table-1 sl-border-t-2px-primary-blue'>
        <tbody class='property-metrics' :class="{ 'property-metrics-loading': fetchingMetrics }">
          <tr>
            <td>Energy Star Score</td>
            <td>{{ metrics.energy_star_score }}</td>
            <td>Electric Consumption</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.annual_electric_consumption }}</span>
              <span v-if='metrics.annual_electric_consumption'>kWh</span>
            </td>
          </tr>
          <tr>
            <td>Source EUI</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.annual_source_eui }}</span>
              <span v-if='metrics.annual_source_eui'>kBtu/sf</span>
            </td>
            <td>Natural Gas Consumption</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.annual_natural_gas_consumption }}</span>
              <span v-if='metrics.annual_natural_gas_consumption'>Therms</span>
            </td>
          </tr>
          <tr>
            <td>Site EUI</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.annual_site_eui }}</span>
              <span v-if='metrics.annual_site_eui'>kBtu/sf</span>
            </td>
            <td>Water Consumption</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.annual_water_consumption }}</span>
              <span v-if='metrics.annual_water_consumption'>kGal</span>
            </td>
          </tr>
          <tr>
            <td>eGrid Emission Factor</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.egrid_emission_factor }}</span>
              <span v-if='metrics.egrid_emission_factor'>kgCO2e/MBtu</span>
            </td>
            <td>Electric Cost</td>
            <td>{{ metrics.annual_electric_cost }}</td>
          </tr>
          <tr>
            <td>GHG Emissions</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.annual_ghg_emissions }}</span>
              <span v-if='metrics.annual_ghg_emissions'>Metric Tons</span>
            </td>
            <td>Gas Cost</td>
            <td>{{ metrics.annual_natural_gas_cost }}</td>
          </tr>
          <tr>
            <td>Direct GHG Emissions</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.direct_ghg_emissions }}</span>
              <span v-if='metrics.direct_ghg_emissions'>Metric Tons</span>
            </td>
            <td>Water Cost</td>
            <td>{{ metrics.annual_water_cost }}</td>
          </tr>
          <tr>
            <td>Indirect GHG Emissions</td>
            <td>
              <span class='espm-data-cell-with-units'>{{ metrics.indirect_ghg_emissions }}</span>
              <span v-if='metrics.indirect_ghg_emissions'>Metric Tons</span>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </form>
    <div v-else class='sl-font-size-14px sl-h-126px sl-border-b-2px
                      sl-flex-justify-content-and-align-items-center'>
      There are no property metrics available for this property.
    </div>
    <br>
  </CollapsibleSection>
</template>
<script>
import axios from 'axios';
import CollapsibleSection from '../../../components/CollapsibleSection.vue';

export default {
  components: {
    CollapsibleSection,
  },
  props: {
    propertyId: {
      type: String,
      required: false,
    },
    projectId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      metrics: null,
      searchDate: null,
      fetchingMetrics: false,
      minDate: null,
      maxDate: null,
      metricsAvailableForDate: true,
      propertyMetricsAvailable: true,
      alertMessage: null,
    };
  },
  mounted() {
    this.fetchMetrics();
  },
  methods: {
    async fetchMetrics() {
      this.fetchingMetrics = true;
      await axios.get('/espm_property_metrics/return_property_metrics_json', {
        params: {
          property_id: this.propertyId,
          project_id: this.projectId,
          search_date: this.searchDate,
        },
      })
        .then((response) => {
          setTimeout(() => {
            this.metrics = response.data.property_metrics;
            this.searchDate = response.data.search_date;
            this.minDate = response.data.min_date;
            this.maxDate = response.data.max_date;
            this.metricsAvailableForDate = response.data.metrics_available_for_date;
            this.propertyMetricsAvailable = response.data.property_metrics_available;
            this.fetchingMetrics = false;
            if (!this.metricsAvailableForDate) {
              this.alertMessage = 'There are no property metrics available for this month.';
            } else {
              this.alertMessage = null;
            }
            this.$emit('metrics-data-ready');
          }, 500);
        })
        .catch(() => {
          this.$emit('metrics-data-ready');
          this.alertMessage = 'There was an error fetching the property metrics.';
        });
    },
  },
};
</script>
<style scoped>
.property-metrics {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.property-metrics-loading {
  opacity: 0.3;
}
</style>
