<template>
  <div class="sl-mb-14px sl-sub-header-blue-10">
    Step 3 of 4 | Enter additional information
  </div>

  <p class="sl-partner-blue-text">Required fields may impact service estimates.</p>

  <!-- Additional Inputs 1 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Year Built</div>
      <input type="text"
              name="year_built"
              maxlength="4"
              class="sl-input-type-1"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              v-model="this.passed_in_order_form_data.property_site_details_data.year_built" />
    </div>

    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Buildings/Site *</div>
      <input type="text"
              name="num_of_buildings"
              maxlength="10"
              class="sl-input-type-1 required-field"
              @click="resetRequiredFieldStyle"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              v-model="this.passed_in_order_form_data.property_site_details_data.num_of_buildings" />
    </div>

    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Building SF *</div>
      <input type="text"
              name="building_sq_ft"
              maxlength="10"
              class="sl-input-type-1 required-field"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
              v-model="this.passed_in_order_form_data.property_site_details_data.building_sq_ft" />
    </div>

    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Land Acreage * 1 ac = 43,560 sq ft</div>
      <input type="text"
              name="land_acreage"
              maxlength="10"
              class="sl-input-type-1 required-field"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
              v-model="this.passed_in_order_form_data.property_site_details_data.land_acreage" />
    </div>
  </div>

  <!-- Additional Inputs  2 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Floors/Building *</div>
      <input type="text"
              name="num_of_floors"
              maxlength="10"
              class="sl-input-type-1 required-field"
              @click="resetRequiredFieldStyle"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              v-model="this.passed_in_order_form_data.property_site_details_data.num_of_floors" />
    </div>

    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Total Units/Rooms/Sites/Beds *</div>
      <input type="text"
              name="num_of_units_rooms_sites"
              maxlength="10"
              class="sl-input-type-1 required-field"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              @click="resetRequiredFieldStyle"
              v-model="this.passed_in_order_form_data.property_site_details_data.num_of_units_rooms_sites" />
    </div>

    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Unit Inspection Percent *</div>
      <select class="sl-input-type-1 required-field"
              name="percent_units_to_inspect"
              v-model="this.passed_in_order_form_data.property_site_details_data.percent_units_to_inspect">
        <option value=""></option>
        <option v-for="(value, key) in this.dropdown_selections.percent_units_to_inspect" :key="key" :value="key">{{ value }}</option>
      </select>
    </div>

    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Total Tenants</div>
      <input type="text"
              name="num_of_tenants"
              maxlength="10"
              class="sl-input-type-1"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              v-model="this.passed_in_order_form_data.property_site_details_data.num_of_tenants" />
    </div>
  </div>

  <!-- Additional Inputs 3 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Total Ground Units</div>
      <input type="text"
              name="ground_floor_units"
              maxlength="10"
              class="sl-input-type-1"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              v-model="this.passed_in_order_form_data.property_site_details_data.ground_floor_units" />
    </div>

    <div style="width: 25%" class="sl-mr-5px">
      <div class="sl-para-small"><br>Development Type</div>
      <select class="sl-input-type-1"
              name="property_development_type"
              v-model="this.passed_in_order_form_data.property_type_data.property_development_type">
        <option value=""></option>
        <option v-for="(value, key) in this.dropdown_selections.development_types" :key="key" :value="value">{{ value }}</option>
      </select>
    </div>

    <div style="width: 25%" class="sl-pr-5px">
      <div class="sl-para-small"><br>Est. Const. Budget</div>
      <input type="text"
              name="estimated_construction_budget"
              maxlength="10"
              class="sl-input-type-1"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
              v-model="this.passed_in_order_form_data.property_site_details_data.estimated_construction_budget" />
    </div>

    <div style="width: 25%;">
      <div class="sl-para-small"><br>Loan Term</div>
      <select class="sl-input-type-1"
              name="loan_term"
              v-model="this.passed_in_order_form_data.loan_data.loan_term">
        <option value=""></option>
        <option v-for="(value, key) in this.dropdown_selections.loan_term_types" :key="key" :value="value">{{ value }}</option>
      </select>
    </div>
  </div>

  <hr/>
  <div class="sl-mtb-10px sl-flex-justify-content-space-btwn">
    <button @click="previous_page" class="sl-simple-outline-btn sl-custom-btn-padding-1">Go Back</button>
    <span v-if="required_fields_missing" class="sl-flex-align-items-center sl-flex-justify-content-end sl-plr-10px sl-required-text"
                                         style="flex-grow: 1;">
      * Enter 0 if the required field is not applicable
    </span>
    <button @click="next_page" class="sl-generic-blue-background-btn sl-custom-btn-padding-1">Continue</button>
  </div>

  <!-- Modal -->
  <div v-if="modal_visibility" class="vue-modal-background">
    <div class="vue-modal-content sl-text-align-center sl-flex-justify-align-center" style="height: 200px;">
      <div>
        <p class="sl-paragraph-med-bold sl-partner-blue-text">Estimated field input preferred.</p>

        <p class="sl-paragraph-med">
          Required fields are used to calculate cost estimates on the next step.
        </p>

        <p class="sl-mtb-20px">
          <button class="sl-simple-outline-btn sl-mr-30px" @click="close_modal">Go Back</button>
        </p>

        <p class="sl-paragraph-med">
          If an estimate is unknown,
          <span class="sl-continue-without-required-fields-link" @click="continue_without_required_fields">click here to continue</span>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
    dropdown_selections: {
      type: Object,
    },
  },

  data() {
    return {
      required_fields_missing: false,
      modal_visibility: false,
    };
  },

  methods: {
    previous_page() {
      this.$emit('previous_page');
    },

    next_page() {
      if (this.check_required_fields()) {
        this.required_fields_missing = true;
        this.show_modal();
        return;
      }

      this.$emit('next_page');
    },

    // Fills in the required fields with 0, then continues to the next page
    continue_without_required_fields() {
      const required_fields = document.getElementsByClassName('required-field');
      for (let i = 0; i < required_fields.length; i++) {
        if (required_fields[i].value === '') {
          required_fields[i].value = 0;
          required_fields[i].dispatchEvent(new Event('input'));
          required_fields[i].dispatchEvent(new Event('change'));
        }
      }

      this.$emit('next_page');
    },

    // check required input fields
    check_required_fields() {
      let required_fields_missing = false;

      const required_fields = document.getElementsByClassName('required-field');
      for (let i = 0; i < required_fields.length; i++) {
        if (required_fields[i].value === '') {
          required_fields_missing = true;
          required_fields[i].classList.add('sl-input-error');
          required_fields[i].parentNode.classList.add('sl-input-title-error');
        } else {
          required_fields[i].classList.remove('sl-input-error');
          required_fields[i].parentNode.classList.remove('sl-input-title-error');
        }
      }

      return required_fields_missing;
    },

    // resets the required field styles on the input and title
    resetRequiredFieldStyle(e) {
      e.target.classList.remove('sl-input-error');
      e.target.parentNode.classList.remove('sl-input-title-error');
    },

    show_modal() {
      this.modal_visibility = true;
    },

    close_modal() {
      this.modal_visibility = false;
    },
  },
};
</script>

<style scoped>
  .vue-modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.1);
  }

  .vue-modal-content {
    background-color: #fefefe;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    max-height: 80%;
    overflow: auto;
  }

  .sl-continue-without-required-fields-link {
    cursor: pointer;
    text-decoration: underline;
  }

  .sl-continue-without-required-fields-link:hover {
    color: var(--primary-blue);
  }
</style>
