<template>
  <div class="sl-mb-14px sl-sub-header-blue-10">
    Step 4 of 4 | Select services & turnaround time
  </div>

  <p class="sl-partner-blue-text">Select a preferred business day (BD) turn around or price for each service to include on the order.</p>

  <Transition>
  <div>
    <!-- Service Estimates and Selections -->
    <table class="sl-custom-table-1 sl-mt-10px">
      <thead>
        <tr>
          <th style="width: 40%;">
            <div class="sl-flex-justify-content-end">
              <span style="font-weight: normal;">SELECT ALL: </span>
            </div>
          </th>

          <th>
            <label class='sl-custom-checkbox sl-pt-2px'  v-if="show_col_1_2_3_title">
              <input type="checkbox" id="select_all_1" @click="select_all($event, 1)" />
              <label for="select_all_1">
                <span class="sl-font-size-12px">15 BD</span>
              </label>
              <span class="checkmark"></span>
            </label>
          </th>

          <th>
            <label class='sl-custom-checkbox sl-pt-2px' v-if="show_col_1_2_3_title">
              <input type="checkbox" id="select_all_2" @click="select_all($event, 2)" />
              <label for="select_all_2">
                <span class="sl-font-size-12px">10 BD</span>
              </label>
              <span class="checkmark"></span>
            </label>
          </th>

          <th>
            <label class='sl-custom-checkbox sl-pt-2px' v-if="show_col_1_2_3_title">
              <input type="checkbox" id="select_all_3" @click="select_all($event, 3)" />
              <label for="select_all_3">
                <span class="sl-font-size-12px">Rush</span>
              </label>
              <span class="checkmark"></span>
            </label>
          </th>

          <!-- show if theres at least 1 "other" type price -->
          <th>
            <label class='sl-custom-checkbox sl-pt-2px' v-if="show_col_4_title">
              <input type="checkbox" id="select_all_4" />
              <label for="select_all_4">
                <span class="sl-font-size-12px">Other</span>
              </label>
              <span class="checkmark" style="visibility: hidden;"></span>
            </label>
          </th>
        </tr>
      </thead>

      <tbody>
        <!-- Renders when Calculate estimates called -->
        <tr v-for="service in passed_in_order_form_data.service_estimates_and_selections_data" :key="service.id"
            v-if="passed_in_order_form_data.service_estimates_and_selections_data.length > 0">
          <td>
            <div>{{ service.department_name }}</div>
            <div class="sl-bold-text">{{ service.service_name }}</div>
            <div v-if="service.conversion_fee > 0" class="sl-bold-text">Includes a ${{ service.conversion_fee }} conversion fee</div>
          </td>

          <td>
            <label class='sl-custom-checkbox sl-pt-2px' v-if="display_col_1_2_3(service)" :name="'price_1_' + service.id">
              <input type="checkbox"
                    :id="'price_1' + service.id"
                    v-model="service.selection"
                    true-value="price_1"
                    false-value=""
                    @click="service_selection_click" />
              <label :for="'price_1' + service.id">
                <span v-if="service.price_1 !== 'RFP'" class="sl-font-size-12px">$</span>
                <span v-if="service.price_algo_1 !== null && service.is_staff">
                  <!-- Display pricing factor data modal for admins only -->
                  <PricingFactorDataModal
                    :service_estimates_and_selections_data="passed_in_order_form_data.service_estimates_and_selections_data"
                    :price="service.price_1"
                    :pricing_data="service.price_algo_1"
                    :conversion_fee="service.conversion_fee"
                  />
                </span>
                <span v-else>{{ service.price_1 }}</span>
              </label>
              <span class="checkmark"></span>
            </label>
          </td>

          <td>
            <label class='sl-custom-checkbox sl-pt-2px' v-if="display_col_1_2_3(service)" :name="'price_2_' + service.id">
              <input type="checkbox"
                    :id="'price_2' + service.id"
                    v-model="service.selection"
                    true-value="price_2"
                    false-value=""
                    @click="service_selection_click" />
              <label :for="'price_2' + service.id">
                <span v-if="service.price_2 !== 'RFP'" class="sl-font-size-12px">$</span>
                <!-- Display pricing factor data modal for admins only -->
                <span v-if="service.price_algo_2 !== null && service.is_staff">
                  <PricingFactorDataModal
                    :service_estimates_and_selections_data="passed_in_order_form_data.service_estimates_and_selections_data"
                    :price="service.price_2"
                    :pricing_data="service.price_algo_2"
                    :conversion_fee="service.conversion_fee"
                  />
                </span>
                <span v-else>{{ service.price_2 }}</span>
              </label>
              <span class="checkmark"></span>
            </label>
          </td>

          <td>
            <label class='sl-custom-checkbox sl-pt-2px' v-if="display_col_1_2_3(service)" :name="'price_3_' + service.id">
              <input type="checkbox"
                    :id="'price_3' + service.id"
                    v-model="service.selection"
                    true-value="price_3"
                    false-value=""
                    @click="service_selection_click" />
              <label :for="'price_3' + service.id">
                <span v-if="service.price_3 !== 'RFP'" class="sl-font-size-12px">$</span>{{ service.price_3 }}
              </label>
              <span class="checkmark"></span>
            </label>
          </td>

          <!-- display only if data in the 4th column "Other is present" -->
          <td >
            <label class='sl-custom-checkbox sl-pt-2px' v-if="display_col_4(service)" :name="'price_4_' + service.id">
              <input type="checkbox"
                    :id="'price_4' + service.id"
                    v-model="service.selection"
                    true-value="price_4"
                    false-value=""
                    @click="service_selection_click" />
              <label :for="'price_4' + service.id">
                <span v-if="service.price_4 !== 'RFP'" class="sl-font-size-12px">$</span>{{ service.price_4 }}
                <span v-if="service.price_4_description"> – </span>{{ service.price_4_description }}
              </label>
              <span class="checkmark"></span>
            </label>
          </td>

          <!-- Remove service btn -->
          <td class="sl-flex-justify-content-end">
            <div>
              <xIcon class="sl-cursor-pointer sl-x-btn" @click="show_modal(service.id)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Grand Total -->
    <div class="sl-flex-justify-content-space-btwn sl-mtb-10px">
      <div style="width: 50%;" class='sl-pl-10px'>
        <!-- TODO: (Paul) -->
        <div class="sl-pb-10px sl-flex-align-items-center">
          <addIcon class="sl-cursor-pointer" @click="jump_to_page(2)" />
          <span class="sl-pl-20px">Add another service</span>
        </div>
        <div class="sl-pt-10px">
          * Total based on the provided property information.
          Field conditions that significantly differ from the referenced information
          and/or additional municipal fees for a zoning report may warrant additional costs.
        </div>
      </div>

      <div style="width: 50%; text-align: right;" class="sl-pr-10px sl-pt-10px">
        <div class="sl-bold-text sl-partner-blue-text">Estimated Grand Total *</div>
        <div class="sl-total-cost-text">{{ this.passed_in_order_form_data.service_total }}</div>
      </div>
    </div>

    <hr/>
    <div class="sl-mtb-10px sl-flex-justify-content-space-btwn">
      <button @click="previous_page" class="sl-simple-outline-btn sl-custom-btn-padding-1">Go Back</button>
      <span v-if="required_fields_missing" class="sl-flex-align-items-center sl-flex-justify-content-end sl-plr-10px sl-required-text"
                                           style="flex-grow: 1;">
        * Remove or make a selection for each service type
      </span>
      <button @click="next_page" class="sl-generic-blue-background-btn sl-custom-btn-padding-1">Review Order</button>
    </div>
  </div>
  </Transition>

  <div class="sl-mt-20px sl-flex-justify-content-center">
    <div class="sl-cursor-pointer sl-flex-justify-align-center sl-charcoal-btn" @click="saveDraftOrder" name="save_as_draft_btn">
      <span class="sl-mr-5px">Save order as draft</span>
      <topArrow style="transform: rotate(90deg);" />
    </div>
  </div>

  <!-- Modal -->
  <div v-if="modal_visibility" class="vue-modal-background">
    <div class="vue-modal-content sl-text-align-center sl-flex-justify-align-center" style="height: 200px;">
      <div>
        <p class="sl-paragraph-med-bold sl-partner-blue-text">Are you sure?</p>

        <p class="sl-paragraph-med">Continuing will remove this service and estimated pricing.
          You will have to go back to step 2 to add it back and be able to include on the order.
        </p>

        <p class="sl-mt-30px">
          <button class="sl-simple-outline-btn sl-mr-30px" @click="close_modal">Cancel</button>
          <a class="sl-simple-outline-btn" @click="remove_service">Continue</a>
        </p>
      </div>
    </div>
  </div>

  <!-- confirmation pop-up -->
  <confirmationPopUp v-if="show_confirmation" :text_1="confirmation_text_1" :text_2="confirmation_text_2" />

  <!-- Overlay -->
  <div v-if="show_overlay" class='sl-general-overlay'></div>
</template>

<script>
/* eslint max-lines: ["error", {"max": 500, "skipComments": true, "skipBlankLines": true}] */
import axios from 'axios';
import xIcon from '../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_20.svg';
import addIcon from '../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_20.svg';
import topArrow from '../../../../assets/images/icons/Icon-To-Top_Charcoal-Dark-100_16.svg';
import confirmationPopUp from './confirmationPopUp.vue';
import PricingFactorDataModal from './PricingFactorDataModal.vue';

export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
    dropdown_selections: {
      type: Object,
    },
  },

  components: {
    confirmationPopUp,
    topArrow,
    xIcon,
    addIcon,
    PricingFactorDataModal,
  },

  data() {
    return {
      required_fields_missing: false,
      current_service_to_be_removed: null,
      modal_visibility: false,
      show_confirmation: false,
      confirmation_text_1: '',
      confirmation_text_2: '',
      show_overlay: false,
      show_col_1_2_3_title: false,
      show_col_4_title: false,
    };
  },

  async mounted() {
    // In case user removes all services, then goes back to a step and comes back
    if (this.passed_in_order_form_data.requested_services_data.length === 0) {
      return;
    }

    // set previous data to save user selections
    const previous_data = {};
    this.passed_in_order_form_data.service_estimates_and_selections_data.forEach((service) => {
      previous_data[service.id] = service.selection;
    });

    // reset total and estimates
    this.passed_in_order_form_data.service_total = '$ - - - -';
    this.passed_in_order_form_data.service_estimates_and_selections_data = [];

    // Only send agency_type and loan_type if agency_type is not empty
    let { agency_type } = this.passed_in_order_form_data.loan_data;
    let { loan_type } = this.passed_in_order_form_data.loan_data;

    if (this.passed_in_order_form_data.loan_data.agency_type === '') {
      agency_type = '';
      loan_type = '';
    }

    // call API to get new estimates
    axios.get('/project_orders/return_calculated_client_scopes', {
      params: {
        agency_type,
        loan_type,
        selected_scopes: this.passed_in_order_form_data.requested_services_data,
        number_of_units: this.passed_in_order_form_data.property_site_details_data.num_of_units_rooms_sites,
        percent_units_to_inspect: this.passed_in_order_form_data.property_site_details_data.percent_units_to_inspect,
        number_of_buildings: this.passed_in_order_form_data.property_site_details_data.num_of_buildings,
        number_of_stories: this.passed_in_order_form_data.property_site_details_data.num_of_floors,
        building_sq_ft: this.passed_in_order_form_data.property_site_details_data.building_sq_ft,
        land_acreage: this.passed_in_order_form_data.property_site_details_data.land_acreage,
        property_type: this.passed_in_order_form_data.property_type_data.primary_property_type,
        property_location: this.passed_in_order_form_data.property_location,
        previous_data,
      },
    })
      .then((response) => {
        this.passed_in_order_form_data.service_estimates_and_selections_data = response.data.service_estimates_and_selections_data;

        // recalculate total
        this.calculate_total();
      })
      .catch((error) => {
        alert(error);
      });
  },

  methods: {
    jump_to_page(page_number) {
      this.$emit('jump_to_page', page_number);
    },

    previous_page() {
      this.$emit('previous_page');
    },

    next_page() {
      if (this.check_required_fields()) {
        this.required_fields_missing = true;
        return;
      }

      this.$emit('next_page');
    },

    // If NOT flat fee, show price_1, price_2, price_3
    display_col_1_2_3(service) {
      if (service.flat_fee === 'false') {
        this.show_col_1_2_3_title = true;
        return true;
      }

      return false;
    },

    // If flat fee, show price_4, OR If price_4 has pricing
    display_col_4(service) {
      if (service.flat_fee === 'true' || service.price_4 !== '') {
        this.show_col_4_title = true;
        return true;
      }

      return false;
    },

    check_required_fields() {
      let required_fields_missing = false;
      // Check if any selections are null
      this.passed_in_order_form_data.service_estimates_and_selections_data.forEach((service) => {
        if (service.selection === null || service.selection === '') {
          required_fields_missing = true;
        }
      });

      if (this.passed_in_order_form_data.service_estimates_and_selections_data.length === 0) {
        required_fields_missing = true;
      }

      return required_fields_missing;
    },

    service_selection_click() {
      // unselect the "select all" checkboxes when a service is selected
      const select_all_ids = ['select_all_1', 'select_all_2', 'select_all_3'];
      select_all_ids.forEach((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });

      this.calculate_total();
    },

    calculate_total() {
      const this_obj = this;

      setTimeout(() => {
        let rfp_selected = false;
        this_obj.passed_in_order_form_data.service_total = 0;

        this_obj.passed_in_order_form_data.service_estimates_and_selections_data.forEach((service) => {
          if (service.selection) {
            if (service[service.selection] === 'RFP') {
              rfp_selected = true;
            } else {
              this_obj.passed_in_order_form_data.service_total += parseFloat(service[service.selection]);
            }
          }
        });

        if (rfp_selected && this_obj.passed_in_order_form_data.service_total > 0) {
          this_obj.passed_in_order_form_data.service_total = `$${this_obj.passed_in_order_form_data.service_total} + RFP`;
        } else if (rfp_selected && this_obj.passed_in_order_form_data.service_total === 0) {
          this_obj.passed_in_order_form_data.service_total = 'RFP';
        } else if (this_obj.passed_in_order_form_data.service_total >= 0) {
          this_obj.passed_in_order_form_data.service_total = `$${this_obj.passed_in_order_form_data.service_total}`;
        } else {
          this_obj.passed_in_order_form_data.service_total = '$ - - - -';
        }
      }, 500);
    },

    show_modal(current_service_to_be_removed_id) {
      this.modal_visibility = true;
      this.current_service_to_be_removed = current_service_to_be_removed_id;
    },

    close_modal() {
      this.modal_visibility = false;
      this.current_service_to_be_removed = null;
    },

    // Remove service from requested_services_data and service_estimates_and_selections_data,
    // then recalculate total
    remove_service() {
      const service_id = this.current_service_to_be_removed;

      this.passed_in_order_form_data.requested_services_data = this.passed_in_order_form_data
        .requested_services_data
        .filter((service) => service.id !== service_id);

      this.passed_in_order_form_data.service_estimates_and_selections_data = this.passed_in_order_form_data
        .service_estimates_and_selections_data
        .filter((service) => service.id !== service_id);

      this.calculate_total();

      // reset current_service_to_be_removed and close modal
      this.close_modal();
    },

    /* eslint-disable no-param-reassign */
    // Select all checkboxes for a given column, also unselects other columns
    select_all(event, column) {
      const select_all_col_1 = document.getElementById('select_all_1');
      const select_all_col_2 = document.getElementById('select_all_2');
      const select_all_col_3 = document.getElementById('select_all_3');

      // if the "select all" checkbox is checked
      if (event.target.checked) {
        this.passed_in_order_form_data.service_estimates_and_selections_data.forEach((service) => {
          // skip iteration if flat fee
          if (service.flat_fee === 'true') {
            return;
          }

          if (column === 1) {
            service.selection = 'price_1';
            select_all_col_2.checked = false;
            select_all_col_3.checked = false;
          } else if (column === 2) {
            service.selection = 'price_2';
            select_all_col_1.checked = false;
            select_all_col_3.checked = false;
          } else {
            service.selection = 'price_3';
            select_all_col_1.checked = false;
            select_all_col_2.checked = false;
          }
        });
      }

      // if the "select all" checkbox is un-checked
      if (!event.target.checked) {
        this.passed_in_order_form_data.service_estimates_and_selections_data.forEach((service) => {
          if (column === 1 && service.selection === 'price_1') {
            service.selection = '';
          } else if (column === 2 && service.selection === 'price_2') {
            service.selection = '';
          } else if (column === 3 && service.selection === 'price_3') {
            service.selection = '';
          }
        });
      }

      this.calculate_total();
    },
    /* eslint-enable no-param-reassign */

    saveDraftOrder() {
      // get params from URL
      const urlParams = new URLSearchParams(window.location.search);
      const project_order_id = urlParams.get('project_order_id');
      this.show_overlay = true;

      axios.post('/project_orders/save_draft_order', {
        order_form_data: this.passed_in_order_form_data,
        project_order_id,
      })
        .then((response) => {
          this.show_confirmation = true;
          this.confirmation_text_1 = 'A draft of your order was successfully saved.';
          this.confirmation_text_2 = `ID: ${response.data.project_order_id}`;

          setTimeout(() => {
            window.location.href = '/project_orders?order_status=Pending';
          }, 5000);
        })
        .catch((error) => {
          alert(`An error occurred: ${error}`);
        })
        .finally(() => {
          this.show_overlay = false;
        });
    },
  },
};
</script>

<style scoped>
  .sl-custom-table-1 {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 2px solid var(--medium-charcoal);
  }

  .sl-custom-table-1 thead {
    border-top: 2px solid var(--primary-blue);
    border-bottom: 2px solid var(--primary-blue);
  }

  .sl-custom-table-1 thead th {
    padding: 10px 10px;
    text-align: justify;
    font-weight: bold;
  }

  .sl-custom-table-1 tbody tr {
    border-bottom: 1px solid var(--medium-charcoal);
  }

  .sl-custom-table-1 tbody td {
    padding: 10px;
    text-align: justify;
  }

  .sl-custom-table-1 .sl-custom-checkbox {
    margin-bottom: 0;
  }

  .vue-modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.1);
  }

  .vue-modal-content {
    background-color: #fefefe;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    max-height: 80%;
    overflow: auto;
  }
</style>
