<template>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 700px;'>
      <div class='sl-flex-justify-content-space-btwn'>
        <div class='sl-w-750px sl-font-size-16px sl-mtb-10px'>Energy and Water Measures Import</div>
        <div class='sl-w-100px sl-mb-20px sl-flex-justify-content-end'>
          <CloseIcon class='figma-icon' @click="$emit('closeModal')" id='sl-history-log-close'/>
        </div>
      </div>
      <div class='sl-border-t-2px-primary-blue sl-border-b-2px sl-ptb-20px'>
        <div class='sl-ml-20px sl-mb-20px'>
          <ol>
            <li class='report-table-instructions'>
              Upload your CSV or input your data directly below.
            </li>
            <li class='report-table-instructions'>
              CSVs must have been created using the Partner template file.
            </li>
            <li class='report-table-instructions'>
              Click <a href='/ewems/download_ewems_template'>here</a> to download the excel template.
            </li>
            <br>
            <span class='report-table-instructions sl-bold-text'>
              Caution: Uploading a file will overwrite existing Ewem data for this project
            </span>
          </ol>
        </div>
        <div class="sl-h-42px"></div>
        <ExcelDropzone
          @upload-complete="$emit('importComplete')"
          :formUrl='`/esg_excel_imports/import_excel_file/?project_id=${projectId}&project_scope_id=${projectScopeId}&db_table=Ewem`'
          :title="'Upload your Energy and Water Measures'"
        />
        <div class="sl-h-84px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import CloseIcon from '../../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import ExcelDropzone from '../../../components/ExcelDropzone.vue';

export default {
  components: {
    CloseIcon,
    ExcelDropzone,
  },

  props: ['projectId', 'projectScopeId'],
};
</script>
