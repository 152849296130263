<template>
  <CollapsibleSection :barText='title'
                      :toggleHeaderColor=false
                      :bottomBorder=false
                      class='sl-mb-20px'>
    <table class='sl-simple-table-1 sl-font-size-14px sl-border-b-2px'>
      <tbody>
        <tr v-for='item in policy' :key='item.db_column'>
          <td>{{ item.display_name }}</td>
          <td v-if="item.data_type === 'url'">
            <a v-if='item.data' :href='item.data' target='_blank'><linkIcon/></a>
            <span v-else>—</span>
          </td>
          <td v-else>{{ item.data }}</td>
        </tr>
      </tbody>
    </table>
    <br>
  </CollapsibleSection>
</template>
<script>
import CollapsibleSection from '../../../components/CollapsibleSection.vue';
import linkIcon from '../../../../../assets/images/icons/Icon-Link_Charcoal-Dark-100_24.svg';

export default {
  components: {
    CollapsibleSection,
    linkIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    policy: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
  table tr {
    height: 42px;
    text-align: left;
  }
  table td:first-child {
    width: 275px;
    color: #696E76;
  }
</style>
