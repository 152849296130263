<template>
  <SpinningModal v-if='showLoadingModal'/>
  <BaselineMetricsImport
    v-if='showImportModal'
    :project='project'
    :baselineMetric='baselineMetric'
    :scheduledImportProject='scheduledImportProject'
    @remove-scheduled-import='scheduledImportProject=null'
    @close-modal='showImportModal = false;'
    @import-data = 'importData'
    @import-excel-data = 'importExcelData'
  />
  <div v-if='dataReady' class='sl-mt-10px sl-mb-20px sl-flex-align-items-center'>
    <h1 class='nd_photos_h1_style' style='margin-right: 0;'>Baseline Metrics</h1>
  </div>
  <br>
  <div v-if='baselineMetric && dataReady'>
    <form @submit.prevent='update' class='sl-w-100per' spellcheck='false'>
      <div class='sl-w-100per sl-text-align-center sl-h-15px'>
        <span v-if='alertMessage' class='sl-partner-blue-text sl-bold-text sl-font-size-14px'>{{ alertMessage }}</span>
        <span v-else style='color: red;'>{{ errorMessage }}</span>
      </div>
      <!-- First Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Property Type -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Property Type</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <select v-model='baselineMetric.crrem_property_type_id'
                      @change='updatePropertyType()'
                      id='crrem_property_type_id'
                      class='sl-border-2px-medium-charcoal sl-pl-5px sl-mb-10px sl-h-37px'>
                <option v-for='propertyType in propertyTypes' :value='propertyType.id'>{{ propertyType.name }}</option>
              </select>
              <div v-if="propertyType==='Mixed-Use'" class='sl-w-100per'>
                <MixedUsePropertyField
                  v-for='mixedUsePropertyType in mixedUsePropertyTypes'
                  @delete='deletePropertyType'
                  :mixedUsePropertyType='mixedUsePropertyType'
                  :mixedUsePropertyTypes='mixedUsePropertyTypes'
                  :baselineMetric='baselineMetric'
                  :propertyTypes='mixedUsepropertyTypeOptions'/>
                <a v-if='mixedUsePropertyTypes.length < 4' class='sl-tooltip'>
                  <img @click='addNewMixedUsePropertyType()'
                      :src=plusIcon class='sl-mr-10px sl-blue-link figma-icon' style='width: 22px;'>
                      <span class='sl-tooltiptext'>Add New Property Type</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Baseline Year -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Baseline Year</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.baseline_year' type='number' :min='baselineYearMin'
                     id='baseline_year'
                     class='sl-disable-number-input-up-down-arrows sl-border-2px-medium-charcoal
                            sl-pl-10px sl-pr-20px sl-h-32px sl-mb-10px'>
            </div>
          </div>
        </div>
      </div>

      <!-- Second Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- State -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>State</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <span class='sl-esg-baseline-disabled-box'>
                {{ baselineMetric.state }}
              </span>
            </div>
          </div>
        </div>
        <!-- Utility Entity -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Utility Entity</span>
              <a v-if='userIsAdmin' href='/utility_entities/new' class='sl-bold-text' target='blank'>Create (Admin)</a>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <select v-model='baselineMetric.utility_entity_id'
                      @change='updateDecarbGoalYear()'
                      id='utility_entity_id'
                      class='sl-border-2px-medium-charcoal sl-pl-5px sl-mb-10px sl-h-37px'>
                <option v-for='utility in utilities' :value='utility.id'>{{ utility.name }}</option>
              </select>
            </div>
        </div>
      </div>
      </div>

      <!-- Third Row-->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Utilty Decarb Goal Year -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box' style='padding-bottom: 10px'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Utility Decarb<br>Goal Year</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.utility_decarb_goal_year' type='number' :max='decarbYearMax'
                     @change='setZeroEmissionGoal()'
                     id='utility_decarb_goal_year'
                     class='sl-disable-number-input-up-down-arrows sl-border-2px-medium-charcoal
                            sl-pl-10px sl-pr-20px sl-h-32px sl-mb-5px'/>
              <span v-if='!zeroEmissionsGoal' style='color: red;'>Not zero emissions</span>
              <span v-else>&nbsp</span>
            </div>
          </div>
        </div>
        <!-- Egrid Subregion -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box' style='padding-bottom: 10px'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Egrid Subregion</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <select v-model='baselineMetric.egrid_subregion_id'
                      @change='updateEgridEmissionFactor()'
                      id='egrid_subregion_id'
                      class='sl-border-2px-medium-charcoal sl-pl-5px sl-mb-5px sl-h-37px'>
                <option v-for='egridSubregion in egridSubregions' :value='egridSubregion.id'>{{ egridSubregion.acronym  }}</option>
              </select>
              <span v-if='!nrelPathwayAvailable' style='color: red;'>NREL Pathway Unavailable</span>
              <span v-else>&nbsp</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Fourth Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Baseline Electric Usage -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Baseline<br>Electric Usage</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.baseline_electric_usage' type='text'
                     @change='formatValue($event)'
                     id='baseline_electric_usage'
                     class='sl-border-2px-medium-charcoal sl-pl-10px
                            sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
        <!-- Total Electric Cost -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Total<br>Electric Cost</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.total_electric_cost' type='text'
                     @change='formatValue($event)'
                     id='total_electric_cost'
                     class='sl-border-2px-medium-charcoal sl-pl-10px
                            sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
      </div>

      <!-- Fifth Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Price Per Kwh -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Price Per kWh</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <span class='sl-esg-baseline-disabled-box'>{{ baselineMetric.price_per_kwh }}</span>
            </div>
          </div>
        </div>
        <!-- Annual Price Escalation -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Annual Electric<br>Price Escalation</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.annual_electric_price_escalation' type='number' step='any'
                     id='annual_electric_price_escalation'
                     class='sl-disable-number-input-up-down-arrows sl-border-2px-medium-charcoal
                            sl-pl-10px sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
      </div>

      <!-- Sixth Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Secondary Fuel Type -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Secondary<br>Fuel Type</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <select v-model='baselineMetric.secondary_fuel_type_id'
                      @change='updateSecondaryFuelType()' id='secondary_fuel_type_id'
                      class='sl-border-2px-medium-charcoal sl-pl-5px sl-mb-5px sl-h-37px'>
                <option v-for='fuelType in secondaryFuelTypes' :value='fuelType.id'>
                  {{ `${fuelType.name} (${fuelType.unit_of_measure})` }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- Secondary Fuel Type Emission Factor -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>{{ secondaryFuelType.display_name }} Emission Factor</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.gas_emission_factor' type='text'
                     @change='formatValue($event)'
                     id='gas_emission_factor'
                     class='sl-border-2px-medium-charcoal sl-pl-10px sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
      </div>

      <!-- Seventh Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Baseline Usage Therms -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Baseline {{ secondaryFuelType.display_name }} Usage</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.baseline_usage_therms' type='text' spellcheck='false'
                     @change='formatValue($event)'
                     id='baseline_usage_therms'
                     class='sl-border-2px-medium-charcoal sl-pl-10px
                            sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
        <!-- Total Gas Cost -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Total {{ secondaryFuelType.display_name }} Cost</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.total_gas_cost' type='text'
                     @change='formatValue($event)'
                     id='total_gas_cost'
                     class='sl-border-2px-medium-charcoal sl-pl-10px
                            sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
      </div>

      <!-- Eighth Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Price Per Therm -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Price Per {{ secondaryFuelType.unit_of_measure }}</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <span class='sl-esg-baseline-disabled-box'>{{ baselineMetric.price_per_therm }}</span>
            </div>
          </div>
        </div>
        <!-- Annual Gas Price Escalation -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Annual {{ secondaryFuelType.display_name }} Price Escalation</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.annual_gas_price_escalation' type='number' step='any'
                     id='annual_gas_price_escalation'
                     class='sl-disable-number-input-up-down-arrows sl-border-2px-medium-charcoal
                            sl-pl-10px sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
      </div>

      <!-- Ninth Row-->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Building Area -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>ESPM<br>Building Area</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.building_area' type='text' spellcheck='false'
                     @change='formatValue($event)'
                     id='building_area'
                     class='sl-border-2px-medium-charcoal sl-pl-10px
                            sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
        <!-- CO2 emissions metricton per mwh -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Grid CO2e<br>Emissions Factor</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.co2_emissions_metricton_per_mwh' type='number' step='any'
                     id='co2_emissions_metricton_per_mwh'
                     class='sl-disable-number-input-up-down-arrows sl-border-2px-medium-charcoal
                            sl-pl-10px sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
      </div>

      <!-- 10th Row-->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- CRREM City -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>CRREM City</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <select v-model='baselineMetric.crrem_city_id'
                      @change='updateCrremCorrectionFactor()'
                      id='crrem_city_id'
                      class='sl-border-2px-medium-charcoal sl-pl-5px sl-mb-10px sl-h-37px'>
                <option v-for='crremCity in crremCities' :value='crremCity.id'>{{ crremCity.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <!-- Local Total Degree Day -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Local Total<br>Degree Day</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.local_total_degree_day' type='text'
                     @change='formatValue($event)'
                     id='local_total_degree_day'
                     class='sl-border-2px-medium-charcoal sl-pl-10px
                            sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
      </div>

      <!-- 11th Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- CRREM Correction Factor -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>CRREM Correction<br>Factor</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-column sl-font-size-14px'>
              <input v-model='baselineMetric.crrem_correction_factor' type='number' step='any'
                     id='crrem_correction_factor'
                     class='sl-disable-number-input-up-down-arrows sl-border-2px-medium-charcoal
                            sl-pl-10px sl-pr-20px sl-h-32px sl-mb-10px'/>
            </div>
          </div>
        </div>
        <!-- Placeholder -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'></div>
        </div>
      </div>

      <!-- 12th Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Primary Carbon Pathway -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Primary Carbon<br>Pathway</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-justify-content-space-btwn'>
              <div class='sl-flex-column sl-w-180px'>
                <label v-for='pathway in carbonPathways.global'
                       :for="`primary_carbon_${pathway.id}`"
                       class='sl-custom-radio'>
                  {{ pathway.name }}
                  <input :id="`primary_carbon_${pathway.id}`"
                         name='primary_carbon_pathway'
                         type='radio'
                         @click="updateCarbonPathway(pathway, 'primary')"
                         :checked="checkCarbonPathway(pathway, 'primary')">
                  <span class='checkmark'></span>
                </label>
              </div>
              <div class='sl-flex-column sl-w-275px'>
                <!-- Custom Carbon Pathways -->
                <label v-for='pathway in carbonPathways.custom'
                       :for='`primary_custom_carbon_${pathway.custom_pathway_id}`'
                       class='sl-custom-radio sl-w-180px'>
                  {{ pathway.name }}
                  <input :id='`primary_custom_carbon_${pathway.custom_pathway_id}`'
                         name='primary_carbon_pathway'
                         type='radio'
                         @click="updateCarbonPathway(pathway, 'primary')"
                         :checked="checkCarbonPathway(pathway, 'primary')">
                  <span class='checkmark'></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- Secondary Carbon Pathway -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Secondary Carbon<br>Pathway</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-justify-content-space-btwn'>
              <div class='sl-flex-column sl-w-180px'>
                <label v-for='pathway in carbonPathways.global'
                       :for="`secondary_carbon_${pathway.id}`"
                       class='sl-custom-radio'>
                  {{ pathway.name }}
                  <input :id="`secondary_carbon_${pathway.id}`"
                         name='secondary_carbon_pathway'
                         type='radio'
                         @click="updateCarbonPathway(pathway, 'secondary')"
                         :checked="checkCarbonPathway(pathway, 'secondary')">
                  <span class='checkmark'></span>
                </label>
              </div>
              <div class='sl-flex-column sl-w-275px'>
                <!-- Custom Carbon Pathways -->
                <label v-for='pathway in carbonPathways.custom'
                       :for='`secondary_custom_carbon_${pathway.custom_pathway_id}`'
                       class='sl-custom-radio sl-w-180px'>
                  {{ pathway.name }}
                  <input :id='`secondary_custom_carbon_${pathway.custom_pathway_id}`'
                         name='secondary_carbon_pathway'
                         type='radio'
                         @click="updateCarbonPathway(pathway, 'secondary')"
                         :checked="checkCarbonPathway(pathway, 'secondary')">
                  <span class='checkmark'></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 13th Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Primary EUI Pathway -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Primary EUI<br>Pathway</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-only'>
              <div class='sl-flex-column'>
                <label v-for='pathway in euiPathways' class='sl-custom-radio sl-w-180px'>
                  {{ pathway.name }}
                  <input @click="updateEuiPathway(pathway, 'primary')"
                         :checked="checkEuiPathway(pathway, 'primary')"
                         type='checkbox'/>
                  <span class='checkmark'></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Secondary EUI Pathway -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Secondary EUI<br>Pathway</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-only'>
              <div class='sl-flex-column'>
                <label v-for='pathway in euiPathways' class='sl-custom-radio sl-w-180px'>
                  {{ pathway.name }}
                  <input @click="updateEuiPathway(pathway, 'secondary')"
                         :checked="checkEuiPathway(pathway, 'secondary')"
                         type='checkbox'/>
                  <span class='checkmark'></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 14th Row -->
      <div class='sl-flex-justify-content-center sl-flex-wrap'>
        <!-- Emissions Factor Pathway -->
        <div class='sl-flex-justify-content-end sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'>
            <div class='sl-flex-column sl-flex-align-items-flex-end sl-mr-20px sl-mt-5px'>
              <span class='baseline-field-label'>Emissions Factor<br>Pathway Type</span>
            </div>
            <div class='sl-esg-baseline-inner-box sl-flex-only'>
              <div class='sl-flex-column' >

                <label v-for='pathway in emissionFactorPathways.global'
                       :for='pathway.db_name'
                       class='sl-custom-radio sl-w-180px'>
                  {{ pathway.name }}
                  <input :id='pathway.db_name'
                         name='emission_factor_pathway'
                         type='radio'
                         @click="updateEmissionFactorPathway(pathway)"
                         :checked="checkEmissionFactor(pathway)"/>
                  <span class='checkmark'></span>
                </label>

                <label v-for='pathway in emissionFactorPathways.custom'
                       :for='`custom_emission_factor_${pathway.custom_pathway_id}`'
                       class='sl-custom-radio sl-w-180px'>
                  {{ pathway.name }}
                  <input :id='`custom_emission_factor_${pathway.custom_pathway_id}`'
                         name='emission_factor_pathway'
                         type='radio'
                         @click="updateEmissionFactorPathway(pathway)"
                         :checked="checkEmissionFactor(pathway)"/>
                  <span class='checkmark'></span>
                </label>

              </div>
            </div>
          </div>
        </div>

        <!-- Placeholder -->
        <div class='sl-flex-justify-content-start sl-w-50per sl-min-w-540px'>
          <div class='sl-esg-baseline-form-box'></div>
        </div>
      </div>

      <br>
      <!-- Save Button -->
      <div class='sl-flex-justify-content-center sl-mt-20px'>
        <button type='submit' class='sl-simple-outline-btn sl-w-130px sl-h-32px  sl-mr-20px'>Save</button>
      </div>
    </form>
  </div>
</template>
<script>
/* eslint max-lines: off */
import axios from 'axios';
import MixedUsePropertyField from './MixedUsePropertyTypeField.vue';
import BaselineMetricsImport from './BaselineMetricsImport.vue';
import SpinningModal from '../../../components/SpinningModal.vue';
import plusIcon from '../../../../../assets/images/icons/New_Report_Blue.png';

export default {
  components: {
    MixedUsePropertyField,
    BaselineMetricsImport,
    SpinningModal,
  },

  data() {
    return {
      baselineMetric: null,
      baselineYearMin: null,
      decarbYearMax: null,
      propertyType: null,
      propertyTypes: [],
      mixedUsePropertyTypes: [],
      mixedUsepropertyTypeOptions: [],
      utilities: [],
      egridSubregions: [],
      crremCities: [],
      carbonPathways: [],
      euiPathways: [],
      emissionFactorPathways: [],
      egridSubregion: null,
      zeroEmissionsGoal: true,
      nrelPathwayAvailable: true,
      userIsAdmin: null,
      showLoadingModal: false,
      alertMessage: null,
      errorMessage: null,
      showImportModal: false,
      plusIcon,
      scheduledImportProject: null,
      secondaryFuelTypes: [],
      secondaryFuelType: {},
      dataReady: false,

    };
  },

  async created() {
    this.fetchBaselineMetric();
    this.importModalButtonEventListener();
  },

  methods: {
    async fetchBaselineMetric() {
      await axios.get('/baseline_metrics/vue_edit', {
        params: {
          id: this.getBaselineMetricId(),
        },
      })
        .then((response) => {
          this.scheduledImportProject = response.data.scheduledImportProject;
          this.baselineMetric = response.data.baselineMetric;
          this.project = response.data.project;
          this.propertyType = response.data.propertyType;
          this.mixedUsePropertyTypes = response.data.mixedUsePropertyTypes;
          this.egridSubregion = response.data.egridSubregion;
          this.baselineYearMin = response.data.baselineYearMin;
          this.decarbYearMax = response.data.decarbYearMax;
          this.propertyTypes = response.data.propertyTypes;
          this.utilities = response.data.utilities;
          this.egridSubregions = response.data.egridSubregions;
          this.crremCities = response.data.crremCities;
          this.carbonPathways = response.data.carbonPathways;
          this.euiPathways = response.data.euiPathways;
          this.emissionFactorPathways = response.data.emissionFactorPathways;
          this.customCarbonPathwayId = response.data.customCarbonPathwayId;
          this.secondaryFuelTypes = response.data.secondaryFuelTypes;
          this.secondaryFuelType = response.data.secondaryFuelType;
          this.userIsAdmin = response.data.userIsAdmin;
          this.updatemixedUsepropertyTypeOptions();
          this.setZeroEmissionGoal();
          this.dataReady = true;
        })
        .catch(() => {
          this.errorMessage = 'Error, something went wrong. Contact admin@sitelynx.net for support.';
        });
    },

    // Called on form save
    async update() {
      this.showLoadingModal = true;
      await axios.put(`/baseline_metrics/${this.baselineMetric.id}`, null, {
        params: {
          baseline_metric: this.baselineMetric,
          mixed_use_property_types: this.mixedUsePropertyTypes,
        },
      })
        .then((response) => {
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.baselineMetric = response.data.baselineMetric;
          this.alertMessage = response.data.message;
          this.mixedUsePropertyTypes = response.data.mixedUsePropertyTypes;
          this.setZeroEmissionGoal();
          setTimeout(() => { this.alertMessage = null; }, 3000);
        })
        .catch((error) => {
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.errorMessage = 'Error, unable to save.';
          this.errorMessage = Object.values(error.response.data.message)[0].join();
        });
    },

    // Get the baseline metric id from the url on initial page load
    getBaselineMetricId() {
      return window.location.href.split('baseline_metrics/')[1].split('/edit')[0];
    },

    // Update the string of eui pathways selected in the multiple choice field,
    // pathway names are saved in the db as a string seperated by a semicolon and space
    updateEuiPathway(pathway, category) {
      const column = `${category}_eui_pathway`;
      const customColumn = `${category}_custom_eui_pathway`;
      this.baselineMetric[column] = pathway.id;
      this.baselineMetric[customColumn] = pathway.custom_pathway_id;
    },

    // Update the emission factor pathway single choice
    updateEmissionFactorPathway(pathway) {
      this.baselineMetric.emission_factor_pathway_type = pathway.db_name;
      this.baselineMetric.custom_emission_factor_pathway = pathway.custom_pathway_id;
    },

    // Update the property type as field is changed. If mixed use is selected adjustments are made to show additional fields
    async updatePropertyType() {
      const propertyTypeId = this.baselineMetric.crrem_property_type_id;
      const propertyType = this.propertyTypes.find((value) => value.id === propertyTypeId);
      this.propertyType = propertyType.name;
      if (this.propertyType === 'Mixed-Use' && this.mixedUsePropertyTypes.length === 0) {
        this.addNewMixedUsePropertyType();
      }
    },

    // Remove 'Mixed-Use' as an option in the drop down when selecting mixed use property types
    updatemixedUsepropertyTypeOptions() {
      const { propertyTypes } = this;
      const index = propertyTypes.findIndex((element) => element.name === 'Mixed-Use');
      this.mixedUsepropertyTypeOptions = propertyTypes.filter((_, i) => i !== index);
    },

    // When adding a new mixed use property type to the baseline metric
    async addNewMixedUsePropertyType() {
      await axios.post('/esg_mixed_use_property_types', null, {
        params: {
          baseline_metric_id: this.baselineMetric.id,
        },
      })
        .then((response) => {
          this.mixedUsePropertyTypes = response.data.mixedUsePropertyTypes;
        })
        .catch(() => {
          this.errorMessage = 'Error, unable to save.';
        });
    },

    // When deleting a mixed use property type from a baseline metric
    async deletePropertyType(id) {
      await axios.delete(`/esg_mixed_use_property_types/${id}`)
        .then((response) => {
          this.mixedUsePropertyTypes = response.data.mixedUsePropertyTypes;
        })
        .catch(() => {
          this.errorMessage = 'Error, unable to save.';
        });
    },

    // Return true if the emission factor pathway is selected
    checkEmissionFactor(pathway) {
      if (pathway.custom_pathway_id) {
        return this.baselineMetric.custom_emission_factor_pathway === pathway.custom_pathway_id;
      }
      return this.baselineMetric.emission_factor_pathway_type === pathway.db_name;
    },

    // Return true if the eui pathway is selected
    checkEuiPathway(pathway, category) {
      const column = `${category}_eui_pathway`;
      const custom_column = `${category}_custom_eui_pathway`;
      if (pathway.custom_pathway_id) {
        return this.baselineMetric[column] === pathway.id && this.baselineMetric[custom_column] === pathway.custom_pathway_id;
      }
      return this.baselineMetric[column] === pathway.id;
    },

    // Format numbers as currency or with delimeter and units when fields are changed
    formatValue(e) {
      const column = e.target.id;
      let { value } = e.target;
      const { unit_of_measure } = this.secondaryFuelType;
      // Replace non-numerical characters
      value = value.replace(/[^\d.-]/g, '');
      if (Number.isNaN(value)) { value = 0; }

      switch (column) {
      case 'baseline_electric_usage':
        this.updatePricePerKwh();
        value = `${this.numberWithDelimiter(value)} kWh`;
        break;
      case 'total_electric_cost':
        this.updatePricePerKwh();
        value = this.numberToCurrency(value);
        break;
      case 'baseline_usage_therms':
        this.updatePricePerTherm();
        value = `${this.numberWithDelimiter(value)} ${unit_of_measure}`;
        break;
      case 'total_gas_cost':
        this.updatePricePerTherm();
        value = this.numberToCurrency(value);
        break;
      case 'building_area':
        value = `${this.numberWithDelimiter(value)} sq ft`;
        break;
      case 'local_total_degree_day':
        this.updateCrremCorrectionFactor();
        value = this.numberWithDelimiter(Math.round(value));
        break;
      case 'gas_emission_factor':
        value = `${value} tCO2e/${unit_of_measure}`;
        break;
      default:
        break;
      }
      this.baselineMetric[column] = value;
    },

    // When electric usage or cost is changed, update the price per kwh field
    updatePricePerKwh() {
      const usage = this.baselineMetric.baseline_electric_usage.replace(/[^\d.-]/g, '');
      const cost = this.baselineMetric.total_electric_cost.replace(/[^\d.-]/g, '');
      let value = cost / usage;
      value = Math.round(value * 100) / 100;
      this.baselineMetric.price_per_kwh = this.numberToCurrency(value);
    },

    // When gas usage or cost is changed, update the price per therm field
    updatePricePerTherm() {
      const usage = this.baselineMetric.baseline_usage_therms.replace(/[^\d.-]/g, '');
      const cost = this.baselineMetric.total_gas_cost.replace(/[^\d.-]/g, '');
      let value = cost / usage;
      value = Math.round(value * 100) / 100;
      this.baselineMetric.price_per_therm = this.numberToCurrency(value);
    },

    // When the utility entity field is changed, update the decarb goal year and show if it is not a zero emission goal
    updateDecarbGoalYear() {
      this.utilities.forEach((entity) => {
        if (entity.id === this.baselineMetric.utility_entity_id) {
          this.baselineMetric.utility_decarb_goal_year = entity.attainment_year;
          this.zeroEmissionsGoal = entity.zero_emissions_goal;
        }
      });
    },

    // Show 'not zero emission goal' text under utility decarb goal year field as needed
    setZeroEmissionGoal() {
      this.utilities.forEach((entity) => {
        if (entity.id === this.baselineMetric.utility_entity_id) {
          if (entity.attainment_year === this.baselineMetric.utility_decarb_goal_year) {
            this.zeroEmissionsGoal = entity.zero_emissions_goal;
          } else {
            this.zeroEmissionsGoal = true;
          }
        }
      });
    },

    // Update Grid CO2e Emissions Factor when egrid subregion is changed
    async updateEgridEmissionFactor() {
      this.egridSubregions.forEach((region) => {
        if (region.id === this.baselineMetric.egrid_subregion_id) {
          this.nrelPathwayAvailable = region.nrel_pathway_available;
        }
      });
      this.showLoadingModal = true;
      await axios.post('/baseline_metrics/update_egrid_subregion', null, {
        params: {
          id: this.baselineMetric.id,
          egrid_subregion_id: this.baselineMetric.egrid_subregion_id,
        },
      })
        .then((response) => {
          this.baselineMetric.co2_emissions_metricton_per_mwh = response.data.emission_factor;
          setTimeout(() => { this.showLoadingModal = false; }, 500);
        })
        .catch(() => {
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.errorMessage = 'Error, unable to save.';
        });
    },

    // Update Crrem correction factor when Crrem city is changed
    async updateCrremCorrectionFactor() {
      this.showLoadingModal = true;
      await axios.post('/baseline_metrics/update_crrem_city', null, {
        params: {
          id: this.baselineMetric.id,
          crrem_city_id: this.baselineMetric.crrem_city_id,
          local_total_degree_day: this.baselineMetric.local_total_degree_day,
        },
      })
        .then((response) => {
          this.baselineMetric.crrem_correction_factor = response.data.crrem_correction_factor;
          setTimeout(() => { this.showLoadingModal = false; }, 500);
        })
        .catch(() => {
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.errorMessage = 'Error, unable to save.';
        });
    },

    // Project Data Transfer
    async importData(importParams) {
      this.showLoadingModal = true;
      this.showImportModal = false;
      await axios.post('/baseline_metrics/import_from_existing_project', null, {
        params: {
          id: this.baselineMetric.id,
          import_params: importParams,
        },
      })
        .then((response) => {
          this.baselineMetric = response.data.baselineMetric;
          this.scheduledImportProject = response.data.scheduledImportProject;
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.alertMessage = 'Import successful';
          setTimeout(() => { this.alertMessage = ''; }, 3000);
        })
        .catch(() => {
          alert('Something went wrong during the import. Contact admin@sitelynx.net for support.');
        });
    },

    // Import From Excel Workbook
    async importExcelData(file) {
      this.showLoadingModal = true;
      this.showImportModal = false;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('baseline_metric_id', this.baselineMetric.id);
      await axios.post('/esg_excel_imports/import_decarb_excel_workbook', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          this.baselineMetric = response.data.baseline_metric;
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.alertMessage = 'Import successful';
          setTimeout(() => { this.alertMessage = ''; }, 3000);
        })
        .catch(() => {
          this.showLoadingModal = false;
          alert('Something went wrong during the import. Contact admin@sitelynx.net for support.');
        });
    },

    numberWithDelimiter(number) {
      return Intl.NumberFormat().format(number);
    },

    numberToCurrency(number) {
      return `$${Intl.NumberFormat().format(number)}`;
    },

    updateCarbonPathway(pathway, category) {
      const column = `${category}_carbon_pathway`;
      const customColumn = `${category}_custom_carbon_pathway`;
      this.baselineMetric[column] = pathway.id;
      this.baselineMetric[customColumn] = pathway.custom_pathway_id;
    },

    checkCarbonPathway(pathway, category) {
      const column = `${category}_carbon_pathway`;
      const custom_column = `${category}_custom_carbon_pathway`;
      if (pathway.custom_pathway_id) {
        return this.baselineMetric[column] === pathway.id && this.baselineMetric[custom_column] === pathway.custom_pathway_id;
      }
      return this.baselineMetric[column] === pathway.id;
    },

    importModalButtonEventListener() {
      const importButton = document.getElementById('sl-baseline-metric-import-modal-btn');
      importButton.addEventListener('click', () => {
        this.showImportModal = true;
      });
    },

    // When the secondary fuel type is changed, update the unit of measure in the secondary fuel type usage
    // field and update the secondary fuel emission factor input
    updateSecondaryFuelType() {
      this.secondaryFuelTypes.forEach((fuelType) => {
        if (fuelType.id === this.baselineMetric.secondary_fuel_type_id) {
          this.secondaryFuelType = fuelType;
          // Remove the string of unit of measure from the baseline usage field and add the new unit of measure
          let baseline_usage = parseFloat(this.baselineMetric.baseline_usage_therms);
          if (Number.isNaN(baseline_usage)) { baseline_usage = 0; }
          this.baselineMetric.baseline_usage_therms = `${this.numberWithDelimiter(baseline_usage)} ${fuelType.unit_of_measure}`;
          this.baselineMetric.gas_emission_factor = `${fuelType.emission_factor} tCO2e/${fuelType.unit_of_measure}`;
        }
      });
    },
  },
};
</script>
